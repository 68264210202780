import {IProgramRepository} from './interfaces';
import {programs, programDetails} from '../CustomQueries/program';
import {test} from '../CustomQueries/test';
import {Package} from '../API';
import {packageById} from '../CustomQueries/package';
import {getPartnerAuthToken, getPartnerCognitoId} from '../../redux/actions';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class ProgramRepository implements IProgramRepository {
  async getProgram(): Promise<any> {
    return Promise.resolve(undefined);
  }

  async getTestById(testId: string): Promise<any> {
    try {
      let Test: GraphQLResult<any> = await client.graphql({
        query: test,
        variables: {
          id: testId,
        },
        authToken: getPartnerAuthToken(),
      });
      return Test?.data?.test;
    } catch (error) {
      console.log('getTestById Error:', error);
      return Promise.reject(error);
    }
  }

  async getPackageById(packageId: string): Promise<Package> {
    try {
      let packageDetails: GraphQLResult<any> = await client.graphql(
        {
          query: packageById,
          variables: {
            id: packageId,
          },
          authToken: getPartnerAuthToken(),
        },
        {identity: getPartnerCognitoId()},
      );

      //@ts-ignore
      return packageDetails?.data?.package;
    } catch (error) {
      console.log('getPackageByID Error->', error);
      return Promise.reject(error);
    }
  }

  async getPrograms(): Promise<any> {
    try {
      let Programs: GraphQLResult<any> = await client.graphql(
        {
          query: programs,
          variables: {},
          authToken: getPartnerAuthToken(),
        },
        {
          identity: getPartnerCognitoId(),
        },
      );
      console.log('getProgram repository ->', Programs);
      return Programs.data?.programs.items;
    } catch (error) {
      console.log('getProgram Error->', error);
      return Promise.reject(error);
    }
  }

  async getProgramsDetails(): Promise<any> {
    try {
      let ProgramsDetails: GraphQLResult<any> = await client.graphql({
        query: programDetails,
        variables: {},
        authToken: getPartnerAuthToken(),
      });

      return ProgramsDetails.data?.programs.items;
    } catch (error) {
      console.log('getProgram Error->', error);
      return Promise.reject(error);
    }
  }
}
