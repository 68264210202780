import {IProgramService} from './interfaces';
import {ProgramRepository} from '../repositories';

export class ProgramService implements IProgramService {
  ProgramRepository: ProgramRepository;
  constructor() {
    this.ProgramRepository = new ProgramRepository();
  }

  async getProgram(): Promise<any> {
    return Promise.resolve(undefined);
  }

  async getPackageById(id: string): Promise<any> {
    let _package = await this.ProgramRepository.getPackageById(id);
    return _package;
  }

  async getTestById(id: string): Promise<any> {
    let test = await this.ProgramRepository.getTestById(id);
    return test;
  }

  async getPrograms(): Promise<any> {
    let program = await this.ProgramRepository.getPrograms();
    return program;
  }

  async getProgramsDetails(): Promise<any> {
    let programDetails = await this.ProgramRepository.getProgramsDetails();
    return programDetails;
  }
}
