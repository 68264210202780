/**
 * Validate that the given string is a valid email address.
 *
 * @param {string} email - The string to validate.
 * @returns {boolean} true if the string is a valid email address, false otherwise.
 */
export const validateEmail = (email: string) => {
const RegularExpression = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return RegularExpression.test(email);
};

/**
 * Checks if the given string is not empty after trimming whitespace.
 *
 * @param {string} text - The string to check.
 * @returns {boolean} true if the string is not empty after trimming, false otherwise.
 */
export const validateRequireFields = (text: string) => {
  return text.trim().length !== 0;
};

/**
 * Validates if the length of the given string is greater than or equal to the specified minimum length.
 *
 * @param {string} text - The string whose length is to be validated.
 * @param {number} minLength - The minimum length the string should have.
 * @returns {boolean} true if the string's length is greater than or equal to the minimum length, false otherwise.
 */
export const validateMinLength = (text: string, minLength: number) => {
  return text.length >= minLength;
};

/**
 * Validates if the given string is a valid password, i.e. contains at least
 * one lowercase letter, one uppercase letter, one digit, and is at least 6
 * characters long.
 *
 * @param {string} password - The string to validate.
 * @returns {boolean} true if the string is a valid password, false otherwise.
 */
export const validatePassword = (password: string) => {
const mediumRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/
  return mediumRegex.test(password);
};

/**
 * Validates if the given mobile number is valid for the specified country.
 *
 * @param {string} num - The mobile number to validate.
 * @param {string} country - The country code to use for validation (default is 'EG').
 * @returns {boolean} true if the mobile number is valid for the given country, false otherwise.
 */
export const validateMobileNumber = ( num: string,country: string='EG') => {
  const regexMap: { [key: string]: RegExp } = {
    KU: /^\d{8,9}$/,
    EG: /^([0125]\d{8}|(010|011|012|015)\d{8})$/,
    LB: /^\d{7,8}$/,
    DE: /^(\d{1,2}\s?\d{3,11})$/,
    SA: /^5[503614987]\d{7}$/,
  };
  return regexMap[country]?.test(num) ?? false;
};

/**
 * Validates if the given mobile number is valid without considering the country.
 *
 * @param {string} number - The mobile number to validate.
 * @returns {boolean} true if the mobile number is valid, false otherwise.
 */
export const validateMobileNumberWithoutCountry = (number: string) => {
  if (
    /^((\d{9})|(\d{8}))$/.test(number) ||
    /^(010|011|012|015)\d{8}$/.test(number) ||
    /^((\d{9})|(\d{8}))$/.test(number)
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Validates if the given string contains at least one numeric digit.
 *
 * @param {string} number - The string to be checked.
 * @returns {boolean} true if the string contains a numeric digit, false otherwise.
 */
export const validateNumber = (number: string) => {
  return /\d/.test(number);
};


