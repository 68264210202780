import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@fontsource/roboto';
import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {Amplify} from 'aws-amplify';
import {PersistGate} from 'redux-persist/integration/react';
import './App.css';
import {Routers} from './components/Routers';
import './Responsive.scss';
import awsmobile from './amplifyconfiguration.json';
import {persistor, store} from './redux/store';
import {Provider} from 'react-redux';
import {IntercomProvider} from 'react-use-intercom';

import {MobileLandingPage} from './pages';
import InterCom from './pages/Intercom';

Amplify.configure(awsmobile);

const INTERCOM_APP_ID = 'rf39zbw2';

// http://localhost:3000/?partner:CLASSERA?token::pCOFJ8thE543h6K9KI5o3gC1E9
function App() {
  // const {boot, shutdown, hide, show, update} = useIntercom();

  // return isMobile ? (
  //   <MobileLandingPage />
  // ) : (
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={() => {
            // //@ts-ignore
            // store.dispatch(refreshUser());
          }}>
          <BrowserRouter>
            <Switch>
              <Route
                render={() => {
                  return <Routers />;
                }}
              />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </IntercomProvider>
  );
}

export default App;
