export const createEnrollment = /* GraphQL */ `
  mutation CreateEnrollment($input: EnrollmentInsert!) {
    createEnrollment(input: $input) {
      id
      updatedAt
      createdAt
    }
  }
`;

export const updateEnrollment = /* GraphQL */ `
  mutation UpdateEnrollment(
    $id: ID!
    $updatedAt: String!
    $input: EnrollmentUpdate!
  ) {
    updateEnrollment(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const getRandomExerciseWeakSyllables = /* GraphQL */ `
  mutation GetRandomExerciseWeakSyllables($studentId: ID) {
    getRandomExerciseWeakSyllables(studentId: $studentId) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      correctAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      asset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      choices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
      type
    }
  }
`;
