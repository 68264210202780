import React, {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TestState} from '../../redux/reducers/test';
import Button from 'react-bootstrap/Button';
import {Enrollment, Status} from '../../lib';
import {doNextQuestion, setCurrentTest} from '../../redux/actions';

interface AnalysisChoices {
  [key: string]: {
    isCorrect: boolean;
    correctChoice: string;
    givenChoice: string;
  };
}

interface ComprehensionAnswerProps {
  originalText: string;
  analysisChoices: AnalysisChoices;
  onNextQuestion: () => void;
  onLastQuestion: () => void;
}

const ComprehensionAnswer: FC<ComprehensionAnswerProps> = ({
  originalText,
  analysisChoices,
  onNextQuestion,
  onLastQuestion,
}) => {
  const test: TestState = useSelector((state: any) => state.test);
  const selectedEnrollment: Enrollment = useSelector(
    (state: any) => state.enrollment.selectedEnrollment,
  );
  const dispatch = useDispatch();

  const renderTextWithChoices = () => {
    const processedChoices = new Set<string>();
    return originalText
      .split(' ')
      .map((word, index) => {
        const match = word.match(/(CHOICES\d+)/);
        if (match) {
          const choiceKey = match[1];
          if (processedChoices.has(choiceKey)) {
            return null;
          }
          processedChoices.add(choiceKey);
          const analysis = analysisChoices[choiceKey];
          if (analysis) {
            const style = {textDecoration: 'underline', fontSize: 18};
            return (
              <span key={index}>
                {analysis.isCorrect ? (
                  <span style={{...style, color: '#C3E56E'}}>
                    {analysis.correctChoice}
                  </span>
                ) : (
                  <>
                    <span style={{...style, color: '#F2406A'}}>
                      {analysis.givenChoice}
                    </span>
                    <span style={{...style, color: '#C3E56E'}}>
                      ({analysis.correctChoice})
                    </span>
                  </>
                )}{' '}
              </span>
            );
          }
        }
        return (
          <span style={{fontSize: 18}} key={index}>
            {word}{' '}
          </span>
        );
      })
      .filter(Boolean);
  };

  return (
    <div>
      {renderTextWithChoices()}
      {test.isNextQuestion &&
      !selectedEnrollment?.package?.isFree &&
      selectedEnrollment.paymentStatus === Status.DONE ? (
        <div style={{width: '100%', textAlign: 'left', marginTop: 20}}>
          <Button
            type="button"
            className="btn-record"
            data-target=".bd-example-modal-lg"
            onClick={async () => {
              //@ts-ignore
              await dispatch(doNextQuestion());
              onNextQuestion();
              console.log('inside next question');
            }}>
            <span>Next question</span>
          </Button>
        </div>
      ) : (
        (test.isLastQuestion ||
          (selectedEnrollment?.package?.isFree &&
            test.selectedQuestionIndex >= 0)) && (
          <div style={{width: '100%', textAlign: 'left', marginTop: 20}}>
            <Button
              type="button"
              className="btn-record"
              data-target=".bd-example-modal-lg"
              onClick={() => {
                dispatch(setCurrentTest(null));
                onLastQuestion();
                console.log('inside next question');
              }}>
              <span>Done</span>
            </Button>
          </div>
        )
      )}
    </div>
  );
};

export default ComprehensionAnswer;
