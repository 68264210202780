import {IUserService} from './interfaces';
import {UserRepository} from '../repositories';
import {Enrollment, Student, UserUpdate} from '../API';

export class UserService implements IUserService {
  userRepository: UserRepository;

  constructor() {
    this.userRepository = new UserRepository();
  }
  async createUser(
    cognitoUserId: string,
    country: string,
    whatsappNumber: string,
  ): Promise<any> {
    let user = await this.userRepository.createUser(
      cognitoUserId,
      country,
      whatsappNumber,
    );
    return user.data.createUser;
  }

  async getCurrentUser(): Promise<any> {
    let currentUser = await this.userRepository.getCurrentUser();
    currentUser = currentUser.data.me;
    let students = currentUser?.students || [];
    //console.log('currentUser->', currentUser);
    //Sort enrollments by startDate
    students = students.map((student: Student) => {
      let enrollments = student.enrollments;
      enrollments = enrollments?.sort(
        // @ts-ignore
        (enrollment1: Enrollment, enrollment2: Enrollment) =>
          // @ts-ignore
          new Date(enrollment2.createdAt) - new Date(enrollment1.createdAt),
      );
      student.enrollments = enrollments;
      return student;
    });
    currentUser.students = students;
    return currentUser;
  }

  getUserStudents(userId: string): Promise<any> {
    return Promise.resolve(undefined);
  }
  async updateUser(
    userId: string,
    updatedAt: string,
    userUpdate: UserUpdate,
  ): Promise<any> {
    return await this.userRepository.updateUser(userId, updatedAt, userUpdate);
  }
}
