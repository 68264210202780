import {IAnswerService} from './interfaces';
import {AnswerRepository} from '../repositories';

export class AnswerService implements IAnswerService {
  answerRepository: AnswerRepository;

  constructor() {
    this.answerRepository = new AnswerRepository();
  }

  async createAnswer(
    studentId: string,
    questionId: string,
    audioUri: string,
    text: string,
    useEnglishAi: boolean,
    enrollmentId?: string,
    given?: string
  ): Promise<any> {
    let response = await this.answerRepository.createAnswer(
      studentId,
      questionId,
      audioUri,
      text,
      useEnglishAi,
      enrollmentId,
      given
    );
    if (response.analysis) {
      response.analysis = JSON.parse(response.analysis);
    }
    return response;
  }

  async getAnswer(id: string): Promise<any> {
    return await this.answerRepository.getAnswer(id);
  }
}
