import {Modal, Typography, Input} from 'antd';
import Button from 'react-bootstrap/Button';
import React, {useState} from 'react';
import {
  applySubscriptionPromoCode,
  isLoadingRequest,
} from '../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';

type SettingsModalType = {
  closeModal: () => void;
};

export const SettingsModal = ({closeModal}: SettingsModalType) => {
  const dispatch = useDispatch();

  const selectedStudent = useSelector(
    (state: any) => state.student.selectedStudent,
  );
  const {Text} = Typography;

  const [code, setCode] = useState<string>();

  const renderModalFooter = () => {
    return (
      <div className="Buttons_Recording">
        <Button
          onClick={() => {
            //TODO: activate enrollment with given code
            console.log('code->', code);
            if (code) {
              closeModal();
              dispatch(
                //@ts-ignore
                applySubscriptionPromoCode(
                  code.toLowerCase().trim(),
                  selectedStudent.id,
                ),
              );
            }
          }}
          variant=""
          className="btn-record mt-5">
          <span>Subscribe</span>
        </Button>
      </div>
    );
  };
  return (
    <Modal
      className={'exercise-modal'}
      bodyStyle={{textAlign: 'left'}}
      style={{maxHeight: '90%'}}
      width={'60%'}
      centered={true}
      title="Subscription code"
      open={true}
      onOk={() => {}}
      footer={renderModalFooter()}
      onCancel={() => {
        closeModal();
      }}>
      <div>
        <Text>Please enter your code</Text>
        <Input
          onChange={(text) => {
            //@ts-ignore
            setCode(text.target.value);
          }}
          placeholder="Your Code"
        />
      </div>
    </Modal>
  );
};
