import {FetchUserAttributesOutput} from 'aws-amplify/auth';
import {
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_OUT_SUCCESS,
  SIGN_IN__WITH_PARTNER_SUCCESS,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  CREATE_PARENT,
  SIGN_IN,
  RESET_STATE,
  SIGN_OUT,
  SIGN_OUT_ERROR,
  IS_LOGGED_IN,
} from '../../constants';
import type {PikaDoPartners} from '../../lib';

export const initialState: AuthState = {
  user: null,
  partnerUser: null,
  isLoggedIn: false,
  isLoading: false,
  isSignedUp: false,
  error: null,
};

export type PartnerUser = {
  partnerAppName: PikaDoPartners | null;
  sharedStudentId: string | null;
  isAuthenticated: boolean;
  cognitoUserPartnerId: string;
  token?: string | null;
};

export type AuthState = {
  user: FetchUserAttributesOutput | null;
  partnerUser: PartnerUser | null;
  isLoggedIn: boolean;
  isLoading: boolean;
  isSignedUp: boolean;
  error: null | any;
};

const auth = (state = initialState, action: any) => {
  switch (action.type) {
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isSignedUp: true,
        error: null,
      };

    case SIGN_IN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
        isLoading: false,
        error: null,
      };

    case SIGN_UP_ERROR:
      return {
        ...state,
        isLoading: false,
        isSignedUp: false,
        error: action.payload,
      };

    case SIGN_IN_ERROR:
      return {
        ...state,
        isLoading: false,
        partnerUser: null,
        error: action.payload,
      };

    case SIGN_OUT:
      return {
        ...state,
        isLoading: false,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        isLoading: false,
        isSignedUp: false,
        partnerUser: null,
        error: null,
      };
    case SIGN_OUT_ERROR:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        isLoading: false,
        isSignedUp: false,
        partnerUser: null,
        error: null,
      };
    case RESET_STATE:
      return {
        user: null,
        partnerUser: null,
        isLoggedIn: false,
        isLoading: false,
        isSignedUp: false,
        error: null,
      };
    case CREATE_PARENT:
      return {
        ...state,
      };

    case IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true,
      };

    case SIGN_IN__WITH_PARTNER_SUCCESS:
      return {
        ...state,
        partnerUser: action.payload,
        isLoading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default auth;
