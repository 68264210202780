import {Enrollment, Question, Status} from '../API';
import {EnrollmentRepository} from '../repositories';
import {IEnrollmentService} from './interfaces';
import {enrollments} from '../graphql/queries';

export class EnrollmentService implements IEnrollmentService {
  enrollmentRepository: EnrollmentRepository;
  constructor() {
    this.enrollmentRepository = new EnrollmentRepository();
  }

  async getStudentRandomExercise(studentId: string): Promise<Question | null> {
    return await this.enrollmentRepository.getStudentRandomExercise(studentId);
  }

  async levelUpEnrollment(
    enrollmentId: string,
    levelId: string,
    updatedAt: string,
  ): Promise<any> {
    const enrollment = await this.enrollmentRepository.levelUpEnrollment(
      enrollmentId,
      levelId,
      updatedAt,
    );
    return enrollment;
  }

  async getStudentEnrollments(studentId: string): Promise<Enrollment | any> {
    /*return await this.enrollmentRepository.getStudentEnrollments(studentId);*/
    return Promise.reject('Please implement the function');
  }

  async createEnrollment(inputs: {
    studentId: string;
    packageId: string;
    testId: string;
    placementStatus: string;
    paymentStatus: string;
    selfRating: number;
    levelId: string;
  }): Promise<any> {
    const enrollment = await this.enrollmentRepository.createEnrollment(inputs);
    return enrollment;
  }

  async updateEnrollment(inputs: {
    enrollmentId: string;
    packageId: string;
    startDate: string;
    paymentStatus: Status;
    updatedAt: string;
  }): Promise<any> {
    const updatedEnrollment = await this.enrollmentRepository.updateEnrollment(
      inputs,
    );
    return updatedEnrollment;
  }

  async updatePaymentStatus(
    enrollmentId: string,
    status: Status,
    updatedAt: string,
  ): Promise<any> {
    return await this.enrollmentRepository.updatePaymentStatus(
      enrollmentId,
      status,
      updatedAt,
    );
  }

  async updateStartDate(
    enrollmentId: string,
    startDate: string,
    updatedAt: string,
  ): Promise<any> {
    return await this.enrollmentRepository.updateStartDate(
      enrollmentId,
      startDate,
      updatedAt,
    );
  }

  async updatePlacementTestStatus(
    enrollmentId: string,
    status: Status,
    updatedAt: string,
  ): Promise<any> {
    return await this.enrollmentRepository.updatePlacementTestStatus(
      enrollmentId,
      status,
      updatedAt,
    );
  }
}
