import {
  GET_USER,
  RESET_USER,
  NO_USER_FOUND,
  USER_IS_LOADING,
  REFRESH_USER,
} from '../../constants';
import {User} from '../../lib';

export const initialState = {
  user: null,
  isLoading: false,
  error: null,
};

export type UserState = {
  user: User;
  isLoading: boolean;
  error: null | any;
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        error: null,
      };
    case NO_USER_FOUND:
      return {
        ...state,
        user: null,
        isLoading: false,
        error: null,
      };
    case RESET_USER:
      return {
        ...state,
        isLoading: false,
        user: null,
      };
    case USER_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    default:
      return state;
  }
};
