import React, {Dispatch, HTMLAttributes, SetStateAction, useState} from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Swal from 'sweetalert2';
import {validateMinLength} from '../../utils/stringUtils';

interface PhoneNumberInputProps extends HTMLAttributes<HTMLDivElement> {
  setPhone: Dispatch<SetStateAction<string>>;
  setIsPhoneValid: Dispatch<SetStateAction<boolean | undefined>>;
}

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  setPhone,
  setIsPhoneValid,
  className,
}) => {
  const [value, setValue] = useState<string>('');

  /**
   * Validates the phone number.
   * If the phone number is invalid, then shows a warning popup and sets the state isPhoneValid to false.
   * If the phone number is valid, then sets the state isPhoneValid to true.
   */
  const validatePhone = () => {
    if (value === undefined || !validateMinLength(value, 10)) {
      Swal.fire('Please write a valid phone number');
      setIsPhoneValid(false);
    } else {
      setIsPhoneValid(true);
    }
  };
  const changeHandler = (value: any) => {
    setValue(value);
    setPhone(value);
    setIsPhoneValid(
      !(value === undefined || !validateMinLength(value, 10)) && value != '',
    );
  };
  return (
    <PhoneInput
      placeholder="Enter phone number"
      className={className}
      value={value}
      autoComplete="off"
      defaultCountry="EG"
      //@ts-ignore
      onChange={changeHandler}
      onBlur={validatePhone}
    />
  );
};
