import {IStorageService} from './interfaces';
import {StorageRepository} from '../repositories';
import {PikaDoCharacters} from '../API';
import {Amplify} from 'aws-amplify';
import ConfigAWS from '../../amplifyconfiguration.json';
import {getPartnerAuthToken} from '../../redux/actions';
export class StorageService implements IStorageService {
  storageRepository: StorageRepository;
  constructor() {
    this.storageRepository = new StorageRepository();
  }

  async uploadAudioFile(
      userId: string,
      fileUri: string,
      isPlacementTest: boolean,
      questionId: string,
  ): Promise<any> {
    let fileName: string;
    if (getPartnerAuthToken()) {
      isPlacementTest
          ? (fileName =
              userId +
              '/placementTest-' +
              questionId +
              '-' +
              new Date().toISOString() +
              '.aac')
          : (fileName =
              userId + '/' + questionId + new Date().toISOString() + '.aac');
      Amplify.configure({
        ...ConfigAWS,
        //@ts-ignore
        aws_user_files_s3_bucket: ConfigAWS.aws_user_files_s3_bucket,
        Storage: {
          S3: {
            region: ConfigAWS.aws_user_files_s3_bucket_region, // (required) - Amazon S3 bucket region
            bucket: ConfigAWS.aws_user_files_s3_bucket, // (required) - Amazon S3 bucket URI
          },
        },
        auth: {
          //@ts-ignore
          type: 'CUSTOM_AUTH',
          jwtToken: getPartnerAuthToken(),
        },
        aws_appsync_authenticationType: 'AWS_LAMBDA',
      });
    } else {
      isPlacementTest
          ? (fileName =
              'public/' +
              userId +
              '/placementTest-' +
              questionId +
              '-' +
              new Date().toISOString() +
              '.aac')
          : (fileName =
              'public/' +
              userId +
              '/' +
              questionId +
              new Date().toISOString() +
              '.aac');
      Amplify.configure({
        ...ConfigAWS,
        //@ts-ignore
        aws_user_files_s3_bucket: ConfigAWS.aws_user_files_s3_bucket,
        Storage: {
          S3: {
            region: ConfigAWS.aws_user_files_s3_bucket_region, // (required) - Amazon S3 bucket region
            bucket: ConfigAWS.aws_user_files_s3_bucket, // (required) - Amazon S3 bucket URI
          },
        },
      });
    }

    let response = await this.storageRepository.uploadAudioFile(
        fileUri,
        fileName,
    );

    response.path = response?.path?.replaceAll('public/', '');
    response.path = response?.path?.replaceAll('private/', '');
    response.path = response?.path?.replaceAll('protected/', '');

    return response.path;
  }
  async generateWordToSpeech(
      character: PikaDoCharacters,
      text: string,
  ): Promise<any> {
    return await this.storageRepository.generateWordToSpeech(character, text);
  }
}
