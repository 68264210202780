//TODO: Export all custom and amplify queries in one object

export const createEnrollmentCustom = /* GraphQL */ `
  mutation CreateEnrollment($input: EnrollmentInsert!) {
    createEnrollment(input: $input) {
      id
      updatedAt
      createdAt
    }
  }
`;
export const programsCustom = /* GraphQL */ `
  query programs($filter: ProgramsFilter, $limit: Int, $nextToken: String) {
    programs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        test {
          id
        }
        packages {
          id
          createdAt
          number
          updatedAt
          name
          sessionDuration
          price {
            id
            createdAt
            updatedAt
            name
            price
            currency
            country
          }
          description
          benefits
          promoAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
          coverAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
          }
        }
      }
      nextToken
      scannedCount
    }
  }
`;

export const createUserCustom = /* GraphQL */ `
  mutation CreateUser($input: UserInsert!) {
    createUser(input: $input) {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      whatsappNumber
    }
  }
`;

export const createStudentCustom = /* GraphQL */ `
  mutation CreateStudent($input: StudentInsert!) {
    createStudent(input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const createParentCustom = /* GraphQL */ `
  mutation CreateParent($input: ParentInsert!) {
    createParent(input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const customMe = /* GraphQL */ `
  query Me($cognitoUserId: String) {
    me(cognitoUserId: $cognitoUserId) {
      id
      createdAt
      updatedAt
      whatsappNumber
      cognitoUserId
      country
      state
      timeZone
      deviceToken
      devicePlatform
      endPointArn
      firstName
      lastName
    }
  }
`;

export const customCourseByEnrollmentId = /* GraphQL */ `
  query CourseByEnrollmentId($enrollmentId: String!, $plan: String) {
    courseByEnrollmentId(enrollmentId: $enrollmentId, plan: $plan) {
      id
      createdAt
      updatedAt
      name
      plan
      }
    }
  }
`;

export const updateEnrollmentCustom = /* GraphQL */ `
  mutation UpdateEnrollment(
    $id: ID!
    $updatedAt: String!
    $input: EnrollmentUpdate!
  ) {
    updateEnrollment(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        name
        statistic {
          scores
          averageScore
        }
        birthDate
        schoolName
        age
        gender
        notes
        facebookProfileUrl
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        description
        summary
      }
      placementStatus
      paymentStatus
      startDate
      slots {
        id
        createdAt
        updatedAt
        name
        startTime
        endTime
        status
        limit
        current
      }
    }
  }
`;

export const createAnswerCustom = /* GraphQL */ `
  mutation CreateAnswer($input: AnswerInsert!) {
    createAnswer(input: $input) {
      id
      analysis
      analysisChoices
      audioUri
      score
      given
      text
      type
    }
  }
`;

export const createMembershipCustom = /* GraphQL */ `
  mutation CreateMembershipFromSlot($input: MembershipInsertFromSlot!) {
    createMembershipFromSlot(input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const deleteMembershipCustom = /* GraphQL */ `
  mutation deleteMembership($id: ID!) {
    deleteMembership(id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const updateStudentCustom = /* GraphQL */ `
  mutation updateStudent(
    $id: ID!
    $updatedAt: String!
    $input: StudentInsert!
  ) {
    updateStudent(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const getAnswer = /* GraphQL */ `
  query Answer($id: String!) {
    answer(id: $id) {
      id
      createdAt
      updatedAt
      audioUri
      score
    }
  }
`;

export const updateUserr = /* GraphQL */ `
  mutation UpdateUser($id: ID!, $updatedAt: String!, $input: UserUpdate!) {
    updateUser(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      whatsappNumber
      cognitoUserId
      country
      state
      timeZone
      deviceToken
      devicePlatform
      endPointArn
      firstName
      lastName
    }
  }
`;
