export const createStudent = /* GraphQL */ `
  mutation CreateStudent($input: StudentInsert!) {
    createStudent(input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $id: ID!
    $updatedAt: String!
    $input: StudentUpdate!
  ) {
    updateStudent(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      enrollments {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        selfRating
        package {
          id
          onlyAI
          name
          isFree
          packageDuration
          price {
            price
            currency
          }
          program {
            id
            name
            name_i18n
            flag
            supportAi
            packages {
              id
              name
              name_i18n
              sessionDuration
              packageDuration
              description
              description_i18n
              numberOfSessions
              benefits
              benefits_i18n
              details
              details_i18n
              onlyAI
              isFree
              isNative
              isHidden
              enrollmentPackage {
                id
                createdAt
                updatedAt
                number
                onlyAI
                name
                name_i18n
                sessionDuration
                description
                benefits
                benefits_i18n
                details
                details_i18n
                isFree
                numberOfSessions
                description_i18n
                isNative
                isHidden
                packageDuration
              }
              price {
                price
                currency
              }
              promoAsset {
                id
                type
                thumbnailUri
                fileUri
                position
              }
              activities {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
                title_i18n
                subTitle_i18n
                position
              }
              records {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
                title_i18n
                subTitle_i18n
                position
              }
            }
          }
        }
        test {
          id
          createdAt
          updatedAt
        }
      }

      memberships {
        id
        isActive
        package {
          id
          createdAt
          updatedAt
          number
          onlyAI
          name
          name_i18n
          sessionDuration
          description
          benefits
          benefits_i18n
          details
          details_i18n
          isFree
          price {
            price
            currency
          }
        }
        createdAt
        updatedAt
        paymentStatus
        placementStatus
      }
      statistic {
        id
        createdAt
        updatedAt
        scores
        averageScore
      }
      status
      assignedTo
    }
  }
`;

export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent($id: ID!) {
    deleteStudent(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
      }
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      notes
      facebookProfileUrl
      enrollments {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        selfRating
      }
      memberships {
        id
        createdAt
        updatedAt
      }
      statistic {
        id
        createdAt
        updatedAt
        scores
        averageScore
      }
      status
      assignedTo
    }
  }
`;

export const checkDiscountCodeValidation = /* GraphQL */ `
  mutation CheckDiscountCodeValidation(
    $codeName: String!
    $packageId: String!
  ) {
    checkDiscountCodeValidation(codeName: $codeName, packageId: $packageId) {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      isExpired
    }
  }
`;

export const applyPromoCode = /* GraphQL */ `
  mutation ApplyPromoCode($codeName: String!, $studentId: String) {
    applyPromoCode(codeName: $codeName, studentId: $studentId) {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        packageDuration
        productIAP
        typePackage
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        placementStatus
      }
    }
  }
`;
