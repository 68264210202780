import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import pass_icon from '../../assets/pwicon.svg';
import mail_icon from '../../assets/mail.svg';
import Pikado from '../../assets/PikaDoLogo.png';
import {doSignIn} from '../../redux/actions';
import {Loading} from '../Loading';
import {Loader} from '../Loader';
import {Link, useHistory} from 'react-router-dom';
import {validateEmail, validateMinLength} from '../../utils/stringUtils';
import Swal from 'sweetalert2';

export const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const [isEmailValid, setIsEmailValid] = useState<boolean>();
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>();
  const [data, setData] = useState<{email: string; password: string}>({
    email: '',
    password: '',
  });
  const updateData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * Validate that the given string is a valid email address.
   * If invalid, then set the state isEmailValid to false and show a warning popup.
   * If valid, then set the state isEmailValid to true.
   * @param {any} e - The event emitted by the input element.
   */
  const validateEmailInput = (e: any) => {
    if (!validateEmail(data.email)) {
      Swal.fire('Invalid Email');
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  /**
   * Validate that the given string is a valid password.
   * If invalid, then set the state isPasswordValid to false and show a warning popup.
   * If valid, then set the state isPasswordValid to true.
   * @param {any} e - The event emitted by the input element.
   */
  const validatePasswordInput = (e: any) => {
    if (!validateMinLength(data.password, 6)) {
      Swal.fire('Invalid password, minimum length is 6');
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(true);
    }
  };
  const onSignIn = async (e: any) => {
    e.preventDefault();
    //@ts-ignore
    const response = await dispatch(doSignIn(data.email, data.password));
    if (response) {
      history.push('/home');
    }
  };

  return (
    <div>
      <div className="login-page py-4">
        <div className="container vh-100 d-flex flex-column justify-content-center align-items-center">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="bg-white shadow rounded">
                <div className="row">
                  <div className="col-md-5 ps-0 d-none d-md-flex justify-content-center align-items-top pt-5">
                    <div className="form-right  text-white text-center pt-5">
                      <i className="bi bi-bootstrap"></i>

                      <img className="logoo" src={Pikado} />
                      <h2
                        className="fs-3 sign-up-page-title"
                        style={{color: 'black'}}>
                        Welcome Back
                      </h2>
                    </div>
                  </div>

                  <div className="col-md-7 pe-0 login_fields d-flex justify-content-center align-items-center">
                    <div className="form-left  padding_form">
                      <h3 className="mb-3 sign_up_title">
                        Sign in to your account
                      </h3>
                      <form action="" className="row mb-3 g-4">
                        <div className="col-12 ">
                          <label htmlFor="email">
                            E-Mail<span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <div className="input-group-text">
                              <i className="bi bi-person-fill">
                                <img src={mail_icon} alt="mail icon" />
                              </i>
                            </div>
                            <input
                              name="email"
                              id="email"
                              onChange={updateData}
                              onBlur={validateEmailInput}
                              type="text"
                              className="form-control"
                              placeholder="Enter E-Mail"
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <label htmlFor="password">
                            Password<span className="text-pikado-red">*</span>
                          </label>
                          <div className="input-group">
                            <div className="input-group-text">
                              <i className="bi bi-person-fill">
                                <img src={pass_icon} alt="password icon" />
                              </i>
                            </div>
                            <input
                              name="password"
                              type="password"
                              id="password"
                              onChange={updateData}
                              onBlur={validatePasswordInput}
                              className="form-control"
                              placeholder="Enter Password"
                            />
                          </div>
                          <p className="mb-0 pt-2 h6">
                            {' '}
                            <Link
                              to={'/resetPassword'}
                              className="text-pikado-red h6 text-decoration-none fw-bold">
                              Forgot your password
                            </Link>
                          </p>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="inlineFormCheck"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineFormCheck">
                              Remember me
                            </label>
                          </div>
                        </div>

                        <div
                          className="col-12  item_center"
                          style={{marginTop: 0}}>
                          <button
                            onClick={onSignIn}
                            disabled={!isEmailValid || !isPasswordValid}
                            type="submit"
                            className="btn fw-bold px-4  mt-4 bg_button border-0">
                            Sign in
                          </button>
                        </div>
                      </form>
                      <p>
                        {' '}
                        Don't have an account,{' '}
                        <span>
                          <Link
                            to={'/signUp'}
                            className="text-pikado-red fw-bold text-decoration-none text-pikado-red ">
                            sign up
                          </Link>{' '}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader title={'Loading'} />}
    </div>
  );
};
