import React, {memo, useEffect, useRef, useState} from 'react';

import {getFileUrlByKey} from '../../utils/Storage';
import {Loading} from '../Loading';

type RenderVideoType = {
  videoUrl?: string;
  poster?: string;
};

export const RenderVideo = memo(({videoUrl, poster}: RenderVideoType) => {
  const videoPlayerRef = useRef<any>();

  const [img, setImg] = useState<string | undefined>();
  const [fileVideoUrl, setFileVideoUrl] = useState<string | undefined>();
  console.log('videoUrl-MMSS', fileVideoUrl);

  useEffect(() => {
    fetchVideoUrl();
  }, []);

  const fetchVideoUrl = async () => {
    const pathContent = videoUrl?.split('/');
    const bucketName = pathContent ? pathContent[3] : '';

    console.log('bucketNameMina', bucketName);
    console.log('proPicPath', pathContent);

    if (videoUrl) {
      const pathVideo = videoUrl?.split('.pikado.io/');
      let uri2 = videoUrl;
      const videoProfileUrl = await getFileUrlByKey(pathVideo[1], bucketName);

      uri2 = videoProfileUrl.url.toJSON();

      setFileVideoUrl(uri2);
    }

    if (poster) {
      try {
        const pathVideo = poster?.split('.pikado.io/');
        console.log('thumbnailUri00', pathVideo);
        let uri = poster;
        const videoProfileUrl = await getFileUrlByKey(pathVideo[1], bucketName);
        uri = videoProfileUrl.url.toJSON();
        setImg(uri);
        // console.log('uri33', uri);
      } catch (error) {
        console.error('ERRRR:->', error);
      }
    }
    videoPlayerRef?.current?.play();
  };
  return (
    <div style={{display: 'inline-block'}}>
      {fileVideoUrl ? (
        <video
          controlsList="nodownload"
          controls={true}
          onClick={() => {
            videoPlayerRef.current.paused
              ? videoPlayerRef.current.pause()
              : videoPlayerRef.current.play();
          }}
          onError={fetchVideoUrl}
          ref={videoPlayerRef}
          poster={img!}
          src={fileVideoUrl!}
          style={{width: '100%', resize: 'vertical', display: 'flex'}}
          height={'60%'}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
});
