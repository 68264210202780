import React, {memo, useState} from 'react';
import {Enrollment, Program, Student} from '../../lib';
// import {useSelector} from '../../redux/store';
import type {
  StudentDetails,
  studentProgram,
} from '../../redux/reducers/student';


type LanguageButtonProps = {
  onPress: () => void;
  Icon: any;
  item: Program;
  isProgramDisabled: boolean;
  buttonStyle?:any;
};

export const ProgramButton = memo(
  ({
    item,
    Icon,
    onPress,
    buttonStyle,
    isProgramDisabled = false,
  }: LanguageButtonProps) => {
    console.log('SDA', item.flag);
    return (
      <div className='w-25'>

      <button
        style={buttonStyle}
        onClick={onPress}
        className=' rounded-1  border-0 shadow mb-3 container py-2'
        disabled={isProgramDisabled}>
        <div className='row'>
          <div className="col-4">

          <img src={item?.flag ?? ''} className='img-fluid w-50  ms-auto' alt={`${item.name} flag`} />
          </div>
          {/* {'item.flag'} */}
          <p className='col-8 mb-0 text-start d-flex justify-content-start align-items-center' >{item.name}</p>
          {isProgramDisabled && (
            <p>
              {'selected'}
            </p>
          )}
        </div>
      </button>
      </div>
    );
  },
);

