import {IAuthenticationRepository} from './interfaces';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
import {AuthenticatorResponse} from '../API';
import {validatePartnerAuthUser} from '../graphql/mutations';
import {
  rememberDevice,
  sendUserAttributeVerificationCode,
  signIn as signInAmplify,
  SignInOutput,
  signOut as signOutAmplify,
  signUp as signUpAmplify,
  SignUpOutput,
  updateUserAttributes,
} from 'aws-amplify/auth';
const client = generateClient();
export class AuthenticationRepository implements IAuthenticationRepository {
  constructor() {}

  async validatePartnerAuth(
    partnerAppName: string,
    studentId: string,
  ): Promise<AuthenticatorResponse> {
    let token: GraphQLResult<any> = await client.graphql({
      query: validatePartnerAuthUser,
      variables: {
        input: {
          partnerId: studentId,
          partnerAppName,
        },
      },
      authToken: 'partner_auth-' + partnerAppName,
    });

    return token.data.validatePartnerAuthUser;
  }

  async signIn(
    userName: string,
    password: string,
  ): Promise<SignInOutput | any> {
    const res = await signInAmplify({
      username: userName,
      password: password,
    });
    await rememberDevice();
    return res;
  }

  async signOut(): Promise<any> {
    return await signOutAmplify();
  }

  async signUp(
    userName: string,
    password: string,
    phoneNumber: string,
  ): Promise<SignUpOutput> {
    return await signUpAmplify({
      username: userName,
      password: password,
      options: {
        userAttributes: {
          email: userName,
          phone_number: phoneNumber,
        },
      },
    });
  }

  async updateCognitoUser(attributes: object): Promise<any> {
    // const user = await Auth.currentAuthenticatedUser();

    return await updateUserAttributes({
      userAttributes: attributes,
    });
  }

  async sendVerficationCodeUserPhoneNumber(): Promise<any> {
    return await sendUserAttributeVerificationCode({
      userAttributeKey: 'phone_number',
    });
  }

  async sendVerficationCodeUserEmail(): Promise<any> {
    console.log('Sending:');
    return await sendUserAttributeVerificationCode({userAttributeKey: 'email'});
  }

}
