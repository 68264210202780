import {generateClient, GraphQLResult} from 'aws-amplify/api';
import type {ICoursesRepository} from './interfaces';

import type {Course, Level} from '../API';
import * as customQueries from '../customQueries';
import {getPartnerAuthToken} from '../../redux/actions';
import {courseByEnrollmentId, courses, levels} from '../../graphql/queries';
const client = generateClient();
export class CourseRepository implements ICoursesRepository {
  constructor() {}

  async getAllLevels(): Promise<Level[] | any> {
    let levelsResp: GraphQLResult<any> = await client.graphql({
      query: levels,
      variables: {},
      authToken: getPartnerAuthToken(),
    });

    console.log('response->getAllLevels', levelsResp);

    return levelsResp.data.levels.items;
  }

  async getCourses(): Promise<Array<Course>> {
    try {
      let coursesResp: GraphQLResult<any> = await client.graphql({
        query: courses,
        variables: {},
        authToken: getPartnerAuthToken(),
      });

      console.log('response->getCourses', coursesResp);

      return coursesResp.data;
    } catch (e) {
      throw new Error('Error in fetching courses | Error: ' + e);
    }
  }

  async getCourseByEnrollmentId(enrollmentId: string): Promise<any> {
    const planJSON = `d1,d2,d3,d4,d5,d6,d7,d8,d9,d10,d11,d12,d13,d14,d15,d16,d17,d18,d19,d20,d21,d22,d23,d24,d25,d26,d27,d28,d29,d30,d31,`;

    let course: GraphQLResult<any> = await client.graphql({
      query: customQueries.customCourseByEnrollmentId,
      variables: {enrollmentId: enrollmentId, plan: planJSON},
      authToken: getPartnerAuthToken(),
    });

    return course;
  }

  async todayPlanByEnrollmentId(
    enrollmentId: string,
    day: string,
  ): Promise<any> {
    const planJSON = `${day},`;

    console.log('planJSON', planJSON);
    let plan: GraphQLResult<any> = await client.graphql({
      query: courseByEnrollmentId,
      variables: {enrollmentId: enrollmentId, plan: planJSON},
      authToken: getPartnerAuthToken(),
    });

    return plan;
  }
}
