import {
  SELECT_TEST,
  RESET_TEST,
  NEXT_QUESTION,
  LAST_QUESTION,
  SET_CURRENT_TEST,
  LOADING_TEST,
} from '../../constants';
import {Test} from '../../lib';

export type TestState = {
  currentTest: Test | null;
  isNextQuestion: boolean;
  isLastQuestion: boolean;
  selectedQuestionIndex: number;
  isLoading: boolean;
};

export const initialState: TestState = {
  currentTest: null,
  isLastQuestion: true,
  isNextQuestion: false,
  selectedQuestionIndex: 0,
  isLoading: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CURRENT_TEST:
      return {
        ...state,
        currentTest: action.payload,
      };

    case LOADING_TEST:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case SELECT_TEST:
      return {
        ...state,
        currentTest: action.payload,
        isLastQuestion: false,
        isNextQuestion: true,
        selectedQuestionIndex: 0,
      };

    case NEXT_QUESTION:
      return {
        ...state,
        selectedQuestionIndex: action.payload,
      };

    case LAST_QUESTION:
      return {
        ...state,
        isNextQuestion: false,
        isLastQuestion: true,
      };

    case RESET_TEST:
      return {
        ...state,
        currentTest: null,
        isLastQuestion: true,
        isNextQuestion: false,
        selectedQuestionIndex: 0,
      };

    default:
      return state;
  }
};
