import {IS_LOADING_REQUEST, IS_LOADING_REQUEST_LOGIN} from '../../constants';

type errorHandler = {
  message: string;
  errorMessages?: Array<string>;
};
export const isLoadingRequest = (
  isLoading: boolean,
  error: errorHandler | null = null,
) => {
  return {
    type: IS_LOADING_REQUEST,
    payload: {isLoading, error},
  };
};

export const isLoadingLogInRequest = (
  isLoading: boolean,
  error: errorHandler | null = null,
) => {
  return {
    type: IS_LOADING_REQUEST_LOGIN,
    payload: {isLoading, error},
  };
};
