import type {ICourseService} from './interfaces';
import {CourseRepository} from '../repositories';
import {Question, Test} from '../API';

export class CourseService implements ICourseService {
  courseRepository: CourseRepository;
  constructor() {
    this.courseRepository = new CourseRepository();
  }
  async getAllLevels(): Promise<any> {
    return await this.courseRepository.getAllLevels();
  }

  async courseByEnrollmentId(enrollmentId: string): Promise<any> {
    return this.courseRepository.getCourseByEnrollmentId(enrollmentId);
  }

  async todayPlanByEnrollmentId(
    enrollmentId: string,
    day: string,
  ): Promise<any> {
    let today = 'd' + day;
    let courseByEnrollmentId =
      await this.courseRepository.todayPlanByEnrollmentId(enrollmentId, today);

    const plan = JSON.parse(
      courseByEnrollmentId.data.courseByEnrollmentId.plan,
    );
    console.log('PLANANNN->', plan);
    const newSortedPlan = plan[today]?.map((tst: Test) => {
      tst.questions?.sort(
        (a: Question | null, b: Question | null) =>
          //@ts-ignore
          new Date(a.createdAt) - new Date(b.createdAt),
      );

      return tst;
    });

    console.log('newSortedPlan->', newSortedPlan);

    return newSortedPlan;
  }
}
