import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import UnCheckBox from '../../assets/Icons/uncheck-box.svg';
import Swal from 'sweetalert2';
import {ProgramButton} from './ProgramButton';
import {
  addPendingStudent,
  addStudentPrograms,
  deleteStudent,
  isLoadingRequest,
  setPendingStudents,
} from '../../redux/actions';
import {doSetPrograms} from '../../redux/actions/program';
import {StudentDetails, studentProgram} from '../../redux/reducers/student';
import {Enrollment, Program, Student, User} from '../../lib';
import moment from 'moment';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useHistory } from 'react-router-dom';

 const SelectLanguage = () => {
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();
  const [selectedProgram, setSelectedProgram] = useState<
    Array<studentProgram['id']>
  >([]);

  const pendingStudents = useSelector(
    (state:any) => state.student.pendingStudents,
  );

  const programs: Array<Program> = useSelector(
    (state:any) => state.program.programsList,
  );

  const selectedStudent: Student | null = useSelector(
    (state:any) => state.student.selectedStudent,
  );

  const lastStudent = pendingStudents.length - 1;
  const currentStudent: StudentDetails = pendingStudents[lastStudent];
  const isAddingProgram: boolean = selectedStudent?.id === currentStudent?.id;

useEffect(() => {
  dispatch(doSetPrograms());
}, []);

  const userState: User | null = useSelector((state:any) => state.user.user);

  const onPressBack = useCallback(() => {
    const lastStudent = pendingStudents.length - 1;
    if (!isAddingProgram) {
      const targetAge = pendingStudents[lastStudent].targetAge;
      dispatch(deleteStudent(lastStudent));
      // reset({
      //   index: 0,
      //   routes: [{name: routNames.STUDENT_DETAILS_SCREEN, params: {targetAge}}],
      // });
    } else {
      dispatch(setPendingStudents([]));
    }
  }, [pendingStudents, dispatch]);

  const onSave = useCallback(() => {
    const preferredPrograms: Array<studentProgram> = selectedProgram.map(
      programId => {
        const program = programs.find((program: Program) => program.id === programId);
        if (!program) {
          throw new Error(`Program not found with id ${programId}`);
        }
        return {
          // __typename: 'Program',
          ...program,
        };
      },
    );

    if (preferredPrograms.length > 0) {
      dispatch(addStudentPrograms(preferredPrograms));
      // navigate(routNames.SELECT_PROGRAM_LEVEL_SCREEN);
      history.push('/selectLevel')
    } else {
      console.log('please select a language')
      Swal.fire({
        title: "Error",
        text: 'please select a language',
        icon: "error"
      });
      dispatch(
        isLoadingRequest(false, {
          message: 'please select a language',
        }),
      );
    }
  }, [selectedProgram, programs, dispatch]);

  const onSelectProgram = useCallback(
    (    id: string) => {
      if (!selectedProgram.includes(id)) {
        setSelectedProgram(oldArray => [...oldArray, id]);
      } else {
        const temp = [...selectedProgram];
        const indexItem = selectedProgram.indexOf(id);
        temp.splice(indexItem, 1);
        setSelectedProgram(temp);
      }
    },
    [selectedProgram],
  );
  // const propsHeader = IS_RTL
  //   ? {
  //       onPressLeftRight: async () => await Intercom.present(),
  //       leftRightIcon: (
  //         <ChatSupport width={normalize(30)} height={normalize(30)} />
  //       ),
  //     }
  //   : {
  //       onPressFlag: async () => await Intercom.present(),
  //       IconSecondRightName: (
  //         <ChatSupport width={normalize(30)} height={normalize(30)} />
  //       ),
  //     };

  return (
    <div className='container p-4' >
      {/* <div  /> */}
      <div >
        <p className='text-danger h4 text-center mb-3'>
        What language do you want to learn?
        </p>
  <div className=" d-flex justify-content-center align-items-center mb-3">
      {programs.map((item) => 
        item.isActive ? (
          <ProgramButton
            key={item?.id}
           isProgramDisabled={
             (selectedStudent?.enrollments?.some(
               (enroll) => enroll?.package?.program?.id === item?.id
             ) ?? false) && isAddingProgram
           }
            item={item}
            onPress={() => onSelectProgram(item?.id)}
            buttonStyle={{
              backgroundColor: selectedProgram.includes(item?.id)
                ? '#B2D45B'
                : (selectedStudent?.enrollments?.some(
                    (enroll) => enroll?.package?.program?.id === item?.id
                  ) && isAddingProgram)
                  ? '#696969'
                  : '#E6E7E8',
            }}
            Icon={<UnCheckBox />}
          />
        ) : null
      )}
    </div>
      </div>
      <div className=" d-flex justify-content-center align-items-center">

        <button onClick={onSave} className='btn btn-primary'> Save and Continue</button>
      </div>
     
    </div>
  );
};

export default SelectLanguage; 