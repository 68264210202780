export const test = /* GraphQL */ `
  query Test($id: String!) {
    test(id: $id) {
      type
      name
      description
      summary
      questions {
        id
        description
        text
        correctAsset {
          fileUri
        }
      }
    }
  }
`;
