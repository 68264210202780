import {
  doSignOut,
  fetchProgramsDetails,
  fetchSelectedStudent,
  fetchStudents,
  getStudentTodayPlan,
  isLoadingRequest,
} from '.';
import {
  GET_USER,
  IS_LOADING_REQUEST,
  NO_USER_FOUND,
  REFRESH_USER,
  SET_PROGRAMS,
  USER_IS_LOADING,
} from '../../constants';
import {UserService} from '../../lib';

const userService = new UserService();

export const getUser = () => async (dispatch: any) => {
  try {
    let user = await userService.getCurrentUser();
    if (user) {
      dispatch({type: GET_USER, payload: user});
    } else {
      console.log('ERROR_NO_USER_FOUND');
      dispatch({type: NO_USER_FOUND});
    }
  } catch (error) {
    console.log('ERROR_GET_USER', error);
    dispatch({type: USER_IS_LOADING, payload: false});
    dispatch(doSignOut());
  }
};

export const createUser =
  (cognitoUserId: string, country: string, whatsappNumber: string) =>
  async (dispatch: any) => {
    try {
      await userService.createUser(cognitoUserId, country, whatsappNumber);
      dispatch(getUser());
    } catch (e) {
      console.log('catch in createUser->', e);
    }
  };

export const resetUser = () => async (dispatch: any) => {
  try {
    dispatch({type: 'RESET_USER'});
  } catch (error) {
    console.log('reset user-> ', error);
  }
  dispatch(getUser());
};

export const refreshUser = () => async (dispatch: any) => {
  try {
    dispatch(isLoadingRequest(true));
    await dispatch(getUser());
    await dispatch(fetchStudents());
    await dispatch(fetchSelectedStudent());
    await dispatch(getStudentTodayPlan());

    dispatch(isLoadingRequest(false));
  } catch (error: any) {
    dispatch(isLoadingRequest(false));

    console.log('Error refreshing user');
  }
};
