import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import pass_icon from '../../assets/pwicon.svg';
import mail_icon from '../../assets/mail.svg';
import {doSignUp} from '../../redux/actions';
import {PhoneNumberInput, CountryPicker} from '../inputs';
import Pikado from '../../assets/PikaDoLogo.png';
import {Link, useHistory} from 'react-router-dom';
import CheckedBox from '../../assets/CheckedBox.svg';
import {Loader} from '../Loader';
import {validateEmail, validateMinLength} from '../../utils/stringUtils';
import Swal from 'sweetalert2';

const SignUp = () => {
  //const [value, setValue] = '1';
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const [isEmailValid, setIsEmailValid] = useState<boolean>();
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>();
  const [isCountryValid, setIsCountryValid] = useState<boolean>();
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>();
  const [data, setData] = useState<{
    email: string;
    password: string;
    country: string;
    whatsappNumber: string;
  }>({
    email: '',
    password: '',
    country: '',
    whatsappNumber: '',
  });
  const [country, setCountry] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const updateData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    e.target.name == 'email' && setIsEmailValid(validateEmail(e.target.value));
  };

  /**
   * Validate that the given string is a valid email address.
   * If invalid, then set the state isEmailValid to false and show a warning popup.
   * If valid, then set the state isEmailValid to true.
   * @param {any} e - The event emitted by the input element.
   */
  const validateEmailInput = (e: any) => {
    if (!validateEmail(data.email)) {
      Swal.fire('Invalid Email');
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
    }
  };

  /**
   * Validate that the given string is a valid password.
   * If invalid, then set the state isPasswordValid to false and show a warning popup.
   * If valid, then set the state isPasswordValid to true.
   * @param {any} e - The event emitted by the input element.
   */
  const validatePasswordInput = (e: any) => {
    if (!validateMinLength(data.password, 6)) {
      Swal.fire('Password must be at least 6 characters');
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(true);
    }
  };

  const onSignUp = async (e: any) => {
    data.country = country;
    data.whatsappNumber = phone;
    e.preventDefault();

    dispatch(
      //@ts-ignore
      doSignUp(data.email, data.password, data.country, data.whatsappNumber),
    ).then((response: any) => {
      console.log("response",response)
      const params = {
        length: 6,
        route: '/whoNeedToLearn',
        onSubmit: 'verifyEmail',
      };
      response &&  history.push('/verifyOtp', params);
    });
  };

  return (
    <div>
      <div className="login-page  py-4">
        <div className="container py-2  shadow rounded">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="bg-white">
                <div className="row">
                  <div className="col-md-7 pe-0 login_fields">
                    <div className="form-left h-100 padding_form">
                      <form action="" className="row g-4">
                        <div className="col-12 ">
                          <h3 className="mb-3 sign_up_title">
                            Sign up to create a new account
                          </h3>
                          <div className="col-sm-6">
                            <div className="form-check">
                              <img
                                className="img-fluid"
                                src={CheckedBox}
                                alt="checkbox icon"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineFormCheck">
                                To access multiple devices
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-check">
                              <img
                                className="img-fluid"
                                src={CheckedBox}
                                alt="checkbox icon"
                              />

                              <label
                                className="form-check-label"
                                htmlFor="inlineFormCheck">
                                To save learning progress
                              </label>
                            </div>
                          </div>
                          <label htmlFor="email">
                            Your email<span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              autoComplete="off"
                              name="email"
                              id="email"
                              onChange={updateData}
                              onBlur={validateEmailInput}
                              type="text"
                              className={`form-control ${
                                !isEmailValid &&
                                data.email != '' &&
                                'border-danger'
                              }`}
                              placeholder="Enter E-Mail"
                            />{' '}
                            <div className="input-group-text">
                              <i className="bi bi-person-fill">
                                <img src={mail_icon} alt="mail icon" />
                              </i>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <label htmlFor="password">
                            Your new password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              name="password"
                              type="password"
                              id="password"
                              onChange={updateData}
                              onBlur={validatePasswordInput}
                              className="form-control"
                              placeholder="Enter Password"
                            />{' '}
                            <div className="input-group-text">
                              <i className="bi bi-person-fill">
                                <img src={pass_icon} alt="password icon" />
                              </i>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 ">
                          <label htmlFor="country">
                            Your country<span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <CountryPicker
                              id="country"
                              setIsCountryValid={setIsCountryValid}
                              setCountry={setCountry}
                            />
                          </div>
                        </div>
                        <div className="col-12 ">
                          <label htmlFor="phone">
                            Your Whatsapp<span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <PhoneNumberInput
                              id="phone"
                              setIsPhoneValid={setIsPhoneValid}
                              setPhone={setPhone}
                              className={`form-control ${
                                !isPhoneValid && phone != '' && 'border-danger'
                              }`}
                            />
                          </div>
                        </div>
                        <div className="col-12 m-auto item_center">
                          {' '}
                          <p className="text-end text-secondary mt-3 Termsancond">
                            By clicking the sign up button, you accept the
                            <span> Terms & Conditions </span>and the
                            <span> Privacy Policy</span>.
                          </p>
                          <button
                            onClick={onSignUp}
                            disabled={
                              !isEmailValid ||
                              !isPasswordValid ||
                              !isCountryValid ||
                              !isPhoneValid
                            }
                            type="submit"
                            className="btn px-4 mt-4 bg_button border-0">
                            Sign up
                          </button>
                        </div>
                        <Link className="Decoration_none" to="/Signin">
                          <h4 className="haveanaccount">
                            I already have an account,<span> Sign in</span>
                          </h4>
                        </Link>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-5 ps-0 d-none d-md-block">
                    <div className="form-right h-100 text-white text-center pt-5">
                      <i className="bi bi-bootstrap"></i>
                      <h2 className="fs-1">Welcome Back pikado</h2>
                      <img className="img-fluid" src={Pikado} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader title={'Loading'} />}
    </div>
  );
};
export default SignUp;
