import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import NoStar from '../../assets/Icons/no-star.svg';
import FullStar from '../../assets/Icons/full-star.svg';
import QuarterStar from '../../assets/Icons/quarter-star.svg';

import HalfStar from '../../assets/Icons/half-star.svg';
// import ChatSupport from '../../assets/Icons/chat-support.svg';

import SafariBoy from '../../assets/Icons/Boy03.svg';

import {LanguageLevelButton} from './LanguageLevelButton';
import {studentSelfRating} from '../../redux/actions';
import moment from 'moment';
import type {User} from '../../lib';
import { useHistory } from 'react-router-dom';

 const SelectLevel = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const DATA = [
    {
      title: 'Nothing',
      Icon: NoStar ,
    },
    {
      title: 'Individual words',
      Icon: QuarterStar ,
    },
    {
      title: 'Simple phrase',
      Icon: HalfStar ,
    },
    {
      title: 'How to communicate',
      Icon: FullStar ,
    },
  ];

  const pendingStudents = useSelector((state:any) => state.student.pendingStudents);

  const student = useSelector(
    (state:any) => state.student.pendingStudents[pendingStudents.length - 1],
  );

  const lastLangNeed: number = student?.studentPrograms?.length - 1;

  const [currentLanguageIndex, setCurrentLanguageIndex] =
    useState<number>(lastLangNeed);

  const userState: User | null = useSelector((state:any) => state.user.user);

  // useEffect(
  //   React.useCallback(() => {
  //     const startTime = moment();

  //     return () => {
  //       var end = moment(); // another date
  //       if (moment.duration(end.diff(startTime)).asSeconds()) {
  //         console.log(
  //           'LOGIIIITTT',
  //           moment.duration(end.diff(startTime)).asMinutes(),
  //         );

  //         mixpanel.track('duration_time_user_take_in_the_screen', {
  //           userId: userState?.id,
  //           screen: 'selecting student level',
  //           component: 'screen 4 registration',
  //           duration_minutes:
  //             moment.duration(end.diff(startTime)).asMinutes() + ' minute',
  //           duration_in_minutes: moment
  //             .duration(end.diff(startTime))
  //             .asMinutes(),
  //           duration_seconds:
  //             moment.duration(end.diff(startTime)).asSeconds() + ' seconds',
  //           duration_in_seconds: moment
  //             .duration(end.diff(startTime))
  //             .asSeconds(),
  //           userAction: 'false',
  //         });
  //       }
  //     };
  //   }, []),
  // );

  // const onPressBack = useCallback(() => {
  //   reset({
  //     index: 0,
  //     routes: [{name: routNames.SELECT_PROGRAM_SCREEN}],
  //   });
  // }, [reset]);
  const onSelectLevel = useCallback(
    (index: number) => {
      dispatch(
        studentSelfRating({
          langIndex: currentLanguageIndex,
          langLevel: index,
        }),
      );
        history.push('/allCreatedStudents')
      if (currentLanguageIndex - 1 >= 0) {
        setCurrentLanguageIndex(currentLanguageIndex - 1);
      }
      //  else {
      //   reset({
      //     index: 0,
      //     routes: [{name: routNames.CREATED_STUDENTS_SCREEN}],
      //   });
      // }
    },
    [dispatch, currentLanguageIndex],
  );

  // const propsHeader = IS_RTL
  //   ? {
  //       onPressLeftRight: async () => await Intercom.present(),
  //       leftRightIcon: (
  //         <ChatSupport width={normalize(30)} height={normalize(30)} />
  //       ),
  //     }
  //   : {
  //       onPressFlag: async () => await Intercom.present(),
  //       IconSecondRightName: (
  //         <ChatSupport width={normalize(30)} height={normalize(30)} />
  //       ),
  //     };

  return (
    <div className='container py-4'>
      <div className='row flex-column justify-content-center align-items-center'>
        <p className="text-danger text-center h4">
        Select 
        {' '} {student?.studentPrograms[currentLanguageIndex]?.name?student?.studentPrograms[currentLanguageIndex]?.name:"student"}{' '}
           level?
        </p>
        <p  className="w-25 mx-auto  h5">I know</p>
            <ul className = "list-unstyled d-flex flex-column justify-content-center">
              {DATA.map((item, index) => (
                <li key={index} className='row w-25 mx-auto mb-2'>
                  <LanguageLevelButton
                    title={item.title}
                    onPress={() => onSelectLevel(index)}
                    Icon={item.Icon}
                  />
                </li>
              ))}
            </ul>
        
      </div>
      <div >
        {/* <SafariBoy  /> */}
        <img src={SafariBoy} alt="SafariBoy"  className='img-fluid w-25'/>
      </div>
    </div>
  );
};
export default SelectLevel;