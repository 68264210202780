import {ISyllablesRepository} from './interfaces';
import {syllable} from '../../graphql/queries';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class SyllablesRepository implements ISyllablesRepository {
  async getSyllableById(syllableId: string): Promise<any> {
    try {
      let response: GraphQLResult<any> = await client.graphql({
        query: syllable,
        variables: {
          id: syllableId,
        },
      });

      return response.data?.syllable;
    } catch (e) {
      console.log('Fetch test Error: ', e);
    }
  }
}
