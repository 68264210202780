import React, {memo} from 'react';


type LanguageButtonProps = {
  title: string;
  onPress: () => void;
  Icon: any;

};

export const LanguageLevelButton = memo(
  ({title, Icon, onPress}: LanguageButtonProps) => {
    console.log("icon",`'${Icon}'`)
    return (
      <button  onClick={onPress} className='border-0 rounded-1'>
        <div className='row d-flex justify-content-center align-items-center py-2'>
          <div className='col-3 px-0' >
          <img src={Icon} alt={title} className=' img-fluid' />
          </div>
          <div className='col-9' >
          <p className='mb-0 text-start'>{title}</p>
        </div>
        </div>
      </button>
    );
  },
);


