export const getDaysDiff = (date: string): string => {
  // @ts-ignore
  const diffInMs: any = new Date() - new Date(date);
  const diffInDays: string = diffInMs / (1000 * 60 * 60 * 24) + '';
  return parseInt(diffInDays).toString();
};
export const toEnglishNumbers = (arabicStr: string): number => {
  const arabicNumbers = ['١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠'];
  const englishNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  let str = arabicStr
    .split('')
    .map((c) => englishNumbers[arabicNumbers.indexOf(c)] || c)
    .join('');
  return parseInt(str);
};
