import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin, Typography} from 'antd';

type LoaderType = {
  title?: string;
  text?: string;
};

export const Loader = ({title = 'Loading', text}: LoaderType) => {
  const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />;
  const {Text} = Typography;
  return (
    <div className="loader_page">
      <div className="loader_box">
        <Spin indicator={antIcon} style={{marginTop: '50px'}} />
        <Text className="m-auto ">{title}</Text>
        <Text
          style={{margin: 'auto', paddingRight: '20px', paddingLeft: '20px'}}
          className="loader_desc">
          {text}
        </Text>
      </div>
    </div>
  );
};
