import {
  CHANGE_SELECTED_ENROLLMENT,
  GET_RANDOM_TODAY_EXERCISE_ON_WEAK_SYLLABLES,
  GET_STUDENT_ENROLLMENTS,
  SET_SELECTED_ENROLLMENT,
  SET_STUDENT_PLAN,
} from '../../constants';
import {Enrollment, Question} from '../../lib';

export const initialState: EnrollmentState = {
  isLoading: true,
  enrollments: [],
  studentPlan: [],
  todayRandomExercise: null,
  //@ts-ignore
  selectedEnrollment: {
    __typename: 'Enrollment',
  },
};

export type EnrollmentState = {
  isLoading: boolean;
  enrollments: Array<Enrollment | any>;
  selectedEnrollment: Enrollment;
  todayRandomExercise: Question | null;
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_STUDENT_ENROLLMENTS:
      return {
        ...state,
        isLoading: false,
        enrollments: action.payload,
      };
    case CHANGE_SELECTED_ENROLLMENT:
      return state;

    case SET_SELECTED_ENROLLMENT:
      return {
        ...state,
        selectedEnrollment: action.payload,
      };

    case SET_STUDENT_PLAN:
      return {
        ...state,
        studentPlan: action.payload,
      };

    case GET_RANDOM_TODAY_EXERCISE_ON_WEAK_SYLLABLES:
      return {
        ...state,
        todayRandomExercise: action.payload,
      };
    default:
      return state;
  }
};
