import {IUserRepository} from './interfaces';
import * as customQueries from '../customQueries';
import {getPartnerAuthToken, getPartnerCognitoId} from '../../redux/actions';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
import { UserUpdate } from '../API';
const client = generateClient();
//createUserCustom
export class UserRepository implements IUserRepository {
  async getCurrentUser(): Promise<any> {
    if (getPartnerCognitoId()) {
      let response: GraphQLResult<any> = await client.graphql(
        {
          query: customQueries.customMe,
          variables: {
            cognitoUserId: getPartnerCognitoId(),
          },
          authToken: getPartnerAuthToken(),
        },
        {
          identity: getPartnerCognitoId(),
        },
      );
      return response;
    } else {
      let response: GraphQLResult<any> = await client.graphql({
        query: customQueries.customMe,
        variables: {},
        authToken: getPartnerAuthToken(),
      });
      return response;
    }
  }

  async getUserStudents(): Promise<any> {
    //return await API.graphql(graphqlOperation(queries.));
  }

  async createUser(
    cognitoUserId: string,
    country: string,
    whatsappNumber: string,
  ): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: customQueries.createUserCustom,
      variables: {
        input: {
          cognitoUserId: cognitoUserId,
          country: country,
          whatsappNumber: whatsappNumber,
        },
      },
    });

    return response;
  }

  async updateUser(
    userId: string,
    updatedAt: string,
    userUpdate: UserUpdate,
  ): Promise<any> {
    try {
      const updatedUser: GraphQLResult<any> = await client.graphql({
        query: customQueries.updateUserr,
        variables: {
          id: userId,
          updatedAt,
          input: {
            ...userUpdate,
          },
        },
        authToken: getPartnerAuthToken(),
      });

      console.log('response->updatedUser', updatedUser);
      return updatedUser?.data?.updateUser;
    } catch (error) {
      console.log('update updateUser Error: ', error);
      // throw new Error(error);
    }
  }
}
