import {Enrollment, Gender, Membership, Student} from '../API';
import {IStudentRepository} from './interfaces';
import {applyPromoCode, createStudent, deleteStudent, updateStudent} from '../CustomMutations/student';
import {studentsMe, selectedStudent} from '../CustomQueries/student';
import {getPartnerAuthToken, getPartnerCognitoId} from '../../redux/actions';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();

export class StudentRepository implements IStudentRepository {
  async getStudents(): Promise<Array<Student> | []> {
    if (getPartnerCognitoId()) {
      let Students: GraphQLResult<any> = await client.graphql(
        {
          query: studentsMe,
          variables: {
            cognitoUserId: getPartnerCognitoId(),
          },
          authToken: getPartnerAuthToken(),
        },
        {
          identity: getPartnerCognitoId(),
        },
      );

      return Students.data.me.students;
    } else {
      let Students: GraphQLResult<any> = await client.graphql({
        query: studentsMe,
        variables: {},
        authToken: getPartnerAuthToken(),
      });

      return Students.data.me.students;
    }
  }

  async getStudentById(id: string): Promise<Student> {
    let student: GraphQLResult<any> = await client.graphql(
      {
        query: selectedStudent,
        variables: {
          id,
        },
        authToken: getPartnerAuthToken(),
      },
      {
        identity: getPartnerCognitoId(),
      },
    );

    student.data.student.memberships?.sort(function (
      a: Membership,
      b: Membership,
    ) {
      //@ts-ignore
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    return student.data.student;
  }

  async updateStudent(student: Student): Promise<any> {
    await client.graphql({
      query: updateStudent,
      variables: {
        id: student.id,
        updatedAt: student.updatedAt,
        input: {
          name: student.name,
          age: student.age,
          gender: student.gender,
          // schoolName: student.schoolName,
        },
      },
      authToken: getPartnerAuthToken(),
    });
  }
  async applySubscriptionPromoCode(
    codeName: string,
    studentId: string,
  ): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: applyPromoCode,
      variables: {
        codeName,
        studentId,
      },
      authToken: getPartnerAuthToken(),
    });

    return response;
  }

  getEnrollmentsByStudentId(): Promise<Array<Enrollment> | []> {
    return Promise.resolve([]);
  }
  getMembershipsByStudentId(): Promise<Array<Membership> | []> {
    return Promise.resolve([]);
  }

  async createStudent(
    userId: string,
    name: string,
    birthDate: string,
    age: number,
    gender: Gender,
    targetAge: number,
  ): Promise<any> {
    try {
      let stdRes: GraphQLResult<any> = await client.graphql({
        query: createStudent,
        variables: {
          input: {
            userId,
            name,
            birthDate,
            age,
            gender,
            targetAge,
          },
        },
        authToken: getPartnerAuthToken(),
      });

      console.log('stdRes->', stdRes);
      return stdRes.data.createStudent;
    } catch (error) {
      console.log('stdRes Error->', error);
      // crashlytics().recordError(error);
      throw new Error('Error in fetching stdRes | Error: ');
    }
  }

  async deleteStudent(studentId: string): Promise<any> {
    try {
      let studentResp: GraphQLResult<any> = await client.graphql({
        query: deleteStudent,
        variables: {
          id: studentId,
        },
        authToken: getPartnerAuthToken(),
      });

      console.log('studentResp->', studentResp);
      return studentResp.data;
    } catch (error) {
      console.log('deleteStudent Error->', error);
      throw new Error('Error in fetching deleteStudent | Error: ');
    }
  }
}
