/**Auth */
export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_IN__WITH_PARTNER_SUCCESS = 'SIGN_IN__WITH_PARTNER_SUCCESS';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const IS_LOADING_REQUEST_LOGIN = 'IS_LOADING_REQUEST_LOGIN';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';
export const UPDATE_USER_ATTRIBUTES = 'UPDATE_USER_ATTRIBUTES';
export const RESET_STATE = 'RESET_STATE';

/**TEST */
export const SELECT_TEST = 'SELECT_TEST';
export const NEXT_QUESTION = 'NEXT_QUESTION';
export const LAST_QUESTION = 'LAST_QUESTION';
export const RESET_TEST = 'RESET_TEST';
export const SET_CURRENT_TEST = 'SET_CURRENT_TEST';
export const LOADING_TEST = 'LOADING_TEST';

/** Plan */
export const GET_PLAN = 'GET_PLAN';
export const CHANGE_SELECTED_PLAN = 'CHANGE_SELECTED_PLAN';

/**Student */
export const SET_SELECTED_STUDENT = 'SET_SELECTED_STUDENT';
export const SET_STUDENT_DETAILS = 'SET_STUDENT_DETAILS';
export const SET_WAITING_STUDENT = 'SET_WAITING_STUDENT';
export const SET_PENDING_STUDENTS = 'SET_PENDING_STUDENTS';
export const ADD_TO_PENDING_STUDENTS = 'ADD_TO_PENDING_STUDENTS';
export const ADD_SELECTED_PROGRAM = 'ADD_SELECTED_PROGRAM';
export const ADD_SELF_RATING = 'ADD_SELF_RATING';
export const DELETE_STUDENT = 'DELETE_STUDENT';
export const RESET_STUDENT_STATE = 'RESET_STUDENT_STATE';
export const IS_LOADING_STUDENT_STATE = 'IS_LOADING_STUDENT_STATE';
export const SET_DISCOUNT_CODE = 'SET_DISCOUNT_CODE';

export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const SET_CONVERSATION_MESSAGES = 'SET_CONVERSATION_MESSAGES';
export const SET_SUBSCRIBED_ON_CONVERSATION = 'SET_SUBSCRIBED_ON_CONVERSATION';
/**Parent */
export const CREATE_PARENT = 'CREATE_PARENT';

/** User */
export const GET_USER = 'GET_USER';
export const UPDATE_USER_COUNTRY = 'UPDATE_USER_COUNTRY';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const NO_USER_FOUND = 'NO_USER_FOUND';
export const RESET_USER = 'RESET_USER';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const REFRESH_USER = 'REFRESH_USER';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

/** Enrollment */
export const GET_STUDENT_ENROLLMENTS = 'GET_STUDENT_ENROLLMENTS';
export const CHANGE_SELECTED_ENROLLMENT = 'CHANGE_SELECTED_ENROLLMENT';
export const SET_SELECTED_ENROLLMENT = 'SET_SELECTED_ENROLLMENT';
export const SET_STUDENT_PLAN = 'SET_STUDENT_PLAN';
export const GET_RANDOM_TODAY_EXERCISE_ON_WEAK_SYLLABLES =
  'GET_RANDOM_TODAY_EXERCISE_ON_WEAK_SYLLABLES';

//export const GET_STUDENT_ENROLLMENTS = 'GET_STUDENT_ENROLLMENTS';

/**Question */
export const SELECT_QUESTION = 'SELECT_QUESTION';
export const RESET_QUESTION = 'RESET_QUESTION';

/** Program */
export const SET_PROGRAMS = 'SET_PROGRAMS';
export const SET_SELECTED_PROGRAM = 'SET_SELECTED_PROGRAM';
export const SET_PACKAGE_DETAILS = 'SET_PACKAGE_DETAILS';

/** appState */
export const APP_IS_LOADED = 'APP_IS_LOADED';
export const SET_APP_NEED_UPDATE = 'SET_APP_NEED_UPDATE';
export const ON_BOARDING_IS_DONE = 'ON_BOARDING_IS_DONE';
export const IS_LOADING_REQUEST = 'IS_LOADING_REQUEST';
export const SELECTED_LANGUAGE = 'SELECTED_LANGUAGE';
export const IS_APP_LANGUAGE_READY = 'IS_APP_LANGUAGE_READY';
export const IS_PROMPT_REVIEW = 'IS_PROMPT_REVIEW';
export const SET_DATE_PROMPT_REVIEW = 'SET_DATE_PROMPT_REVIEW';

/** Instructor */
export const SET_INSTRUCTORS = 'SET_INSTRUCTORS';
export const SET_REVIEW_QUESTIONS = 'SET_REVIEW_QUESTIONS';
export const SET_IS_LOADING_INSTRUCTOR_STATE =
  'SET_IS_LOADING_INSTRUCTOR_STATE';

/** Slots */
export const SET_INSTRUCTOR_AVAILABLE_DAYS_SLOTS =
  'SET_INSTRUCTOR_AVAILABLE_DAYS_SLOTS';
export const SET_SLOT_IS_LOADING = 'SET_SLOT_IS_LOADING';
export const SET_BOOKED_INSTRUCTOR_SLOT = 'SET_BOOKED_INSTRUCTOR_SLOT';

/** Membership */
export const SET_MEMBERSHIP = 'SET_MEMBERSHIP';
export const SET_MEMBERSHIP_SESSIONS = 'SET_MEMBERSHIP_SESSIONS';
export const SET_CURRENT_MEMBERSHIP_PACKAGE = 'SET_CURRENT_MEMBERSHIP_PACKAGE';
export const SET_NEXT_SESSION = 'SET_NEXT_SESSION';
export const SET_CAN_EDIT_GROUP = 'SET_CAN_EDIT_GROUP';
export const SET_STUDENT_EDIT_GROUP = 'SET_STUDENT_EDIT_GROUP';
export const SET_EDIT_GROUP = 'SET_EDIT_GROUP';
export const SET_PREVIOUS_SESSION = 'SET_PREVIOUS_SESSION';
export const SET_IS_SESSION_REVIEWED = 'SET_IS_SESSION_REVIEWED';
export const SET_CAN_CREATE_GROUP = 'SET_CAN_CREATE_GROUP';
export const SET_SUBSCRIBED_PROMOCODE_ANIMATION =
  'SET_SUBSCRIBED_PROMOCODE_ANIMATION';
