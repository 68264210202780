import startbutton from '../../assets/startbutton.svg';
import Button from 'react-bootstrap/Button';
import WatchIcon from '../../assets/WatchIcon.svg';
import React from 'react';
import {Test} from '../../lib';
import './index.css';
import BasicImageDisplayAuthS3 from '../ImageCard/BasicImageDisplayAuthS3';

type TestCardType = {
  test: Test;
  index: number;
  onClick: () => void;
  isBlur: boolean;
};

export const TestCard = ({
  test,
  index,
  onClick,
  isBlur = false,
}: TestCardType) => {
  return (
    <div
      // className=" col-md-6 col-lg-4 rounded-0"
      className=" exercise-card col-lg-4 col-md-6 col-sm-12"
      key={index}
      id={`${test.id}`}
      onClick={() => {
        !isBlur && onClick();
      }}>
      <div
        className={`card-flyer card-question rounded-0 ${
          isBlur && 'blur'
        } position-relative mx-2`}>
        <div className="text-box  ">
          <div className="image-box rounded-0">
            <BasicImageDisplayAuthS3 thumbnailUri={test?.asset?.thumbnailUri} />

            <img className="img-fluid startbutton" src={startbutton} />
          </div>
          <div className="text-container">
            <h6>{test?.name}</h6>
            <p className="fw-bolder">{test?.summary}</p>
          </div>
        </div>
        <div className="position-absolute bottom-0 end-0 w-100 px-3">
          <Button
            type="button"
            className="btn-test-record rounded-0 mb-3 fw-bold"
            data-toggle="modal"
            data-target=".bd-example-modal-lg"
            onClick={() => {
              !isBlur && onClick();
            }}>
            {/* <img src={WatchIcon} alt="" /> */}
            <span> Watch</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
