/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generateSignedUrlUploadAudioFile = /* GraphQL */ `
  mutation GenerateSignedUrlUploadAudioFile($input: AudioFileInsert!) {
    generateSignedUrlUploadAudioFile(input: $input)
  }
`;
export const addUserToGroup = /* GraphQL */ `
  mutation AddUserToGroup($userName: String!, $groupName: String!) {
    addUserToGroup(userName: $userName, groupName: $groupName)
  }
`;
export const addUserToPreInstructorsGroup = /* GraphQL */ `
  mutation AddUserToPreInstructorsGroup($userName: String!) {
    addUserToPreInstructorsGroup(userName: $userName)
  }
`;
export const levelUpGroup = /* GraphQL */ `
  mutation LevelUpGroup($groupId: String, $levelId: String) {
    levelUpGroup(groupId: $groupId, levelId: $levelId) {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      isGroupHasInstructor
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      nextSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      perviousSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      currentSessionNumber
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      isActive
    }
  }
`;
export const createReviewquestion = /* GraphQL */ `
  mutation CreateReviewquestion($input: ReviewquestionInsert!) {
    createReviewquestion(input: $input) {
      id
      createdAt
      updatedAt
      question_i18n
      reviewType
    }
  }
`;
export const updateReviewquestion = /* GraphQL */ `
  mutation UpdateReviewquestion($id: ID!, $input: ReviewquestionUpdate!) {
    updateReviewquestion(id: $id, input: $input) {
      id
      createdAt
      updatedAt
      question_i18n
      reviewType
    }
  }
`;
export const deleteReviewquestion = /* GraphQL */ `
  mutation DeleteReviewquestion($id: ID!) {
    deleteReviewquestion(id: $id) {
      id
      createdAt
      updatedAt
      question_i18n
      reviewType
    }
  }
`;
export const createPromoCodes = /* GraphQL */ `
  mutation CreatePromoCodes(
    $numOfCodes: Int!
    $packageId: String!
    $sessionPerWeek: Int!
    $schoolId: String
    $levelId: String
  ) {
    createPromoCodes(
      numOfCodes: $numOfCodes
      packageId: $packageId
      sessionPerWeek: $sessionPerWeek
      schoolId: $schoolId
      levelId: $levelId
    )
  }
`;
export const applyPromoCode = /* GraphQL */ `
  mutation ApplyPromoCode($codeName: String!, $studentId: String) {
    applyPromoCode(codeName: $codeName, studentId: $studentId) {
      id
      createdAt
      updatedAt
      codeName
      isApplied
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      sessionPerWeek
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
    }
  }
`;
export const syncClasseraStudents = /* GraphQL */ `
  mutation SyncClasseraStudents($input: ClasseraSchoolInsert!) {
    syncClasseraStudents(input: $input)
  }
`;
export const validatePartnerAuthUser = /* GraphQL */ `
  mutation ValidatePartnerAuthUser($input: ValidatePartnerInsert!) {
    validatePartnerAuthUser(input: $input) {
      token
      expirationDate
    }
  }
`;
export const createDiscountCodes = /* GraphQL */ `
  mutation CreateDiscountCodes($input: DiscountCodeInsert!) {
    createDiscountCodes(input: $input)
  }
`;
export const applyDiscountCode = /* GraphQL */ `
  mutation ApplyDiscountCode(
    $codeName: String!
    $studentId: String!
    $packageId: String!
  ) {
    applyDiscountCode(
      codeName: $codeName
      studentId: $studentId
      packageId: $packageId
    )
  }
`;
export const checkDiscountCodeValidation = /* GraphQL */ `
  mutation CheckDiscountCodeValidation(
    $codeName: String!
    $packageId: String!
  ) {
    checkDiscountCodeValidation(codeName: $codeName, packageId: $packageId) {
      id
      createdAt
      updatedAt
      codeName
      valuePercentage
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      isExpired
      validFrom
      expirationDate
      usage
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
    }
  }
`;
export const createTeacher = /* GraphQL */ `
  mutation CreateTeacher($input: TeacherInsert!) {
    createTeacher(input: $input) {
      id
      createdAt
      updatedAt
      name
      userName
      email
      classesschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
    }
  }
`;
export const updateTeacher = /* GraphQL */ `
  mutation UpdateTeacher(
    $id: ID!
    $updatedAt: String!
    $input: TeacherUpdate!
  ) {
    updateTeacher(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      userName
      email
      classesschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
    }
  }
`;
export const deleteTeacher = /* GraphQL */ `
  mutation DeleteTeacher($id: ID!) {
    deleteTeacher(id: $id) {
      id
      createdAt
      updatedAt
      name
      userName
      email
      classesschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
    }
  }
`;
export const createClassschool = /* GraphQL */ `
  mutation CreateClassschool($input: ClassPartnerInsert!) {
    createClassschool(input: $input) {
      id
      createdAt
      updatedAt
      name
      schoolYear
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      teacher {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
    }
  }
`;
export const updateClassschool = /* GraphQL */ `
  mutation UpdateClassschool(
    $id: ID!
    $updatedAt: String!
    $input: ClassPartnerInsert!
  ) {
    updateClassschool(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      schoolYear
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      teacher {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
    }
  }
`;
export const deleteClassschool = /* GraphQL */ `
  mutation DeleteClassschool($id: ID!) {
    deleteClassschool(id: $id) {
      id
      createdAt
      updatedAt
      name
      schoolYear
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      teacher {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin($input: AdminInsert!) {
    createAdmin(input: $input) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      firstName
      lastName
      address
      SSN
      gender
      isActive
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin($id: ID!, $input: AdminUpdate!) {
    updateAdmin(id: $id, input: $input) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      firstName
      lastName
      address
      SSN
      gender
      isActive
    }
  }
`;
export const createSNSEndPoint = /* GraphQL */ `
  mutation CreateSNSEndPoint($deviceToken: String, $userId: ID) {
    createSNSEndPoint(deviceToken: $deviceToken, userId: $userId)
  }
`;
export const createSNSEndPointInstructor = /* GraphQL */ `
  mutation CreateSNSEndPointInstructor($deviceToken: String, $userId: ID) {
    createSNSEndPointInstructor(deviceToken: $deviceToken, userId: $userId)
  }
`;
export const updateDeviceToken = /* GraphQL */ `
  mutation UpdateDeviceToken($userId: String, $newDeviceToken: String) {
    updateDeviceToken(userId: $userId, newDeviceToken: $newDeviceToken)
  }
`;
export const publishMessage = /* GraphQL */ `
  mutation PublishMessage($title: String, $body: String, $targetArn: String) {
    publishMessage(title: $title, body: $body, targetArn: $targetArn)
  }
`;
export const publishSMSMessage = /* GraphQL */ `
  mutation PublishSMSMessage($message: String, $phoneNumber: String) {
    publishSMSMessage(message: $message, phoneNumber: $phoneNumber)
  }
`;
export const startSession = /* GraphQL */ `
  mutation StartSession($id: ID!) {
    startSession(id: $id) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      moderatorToken
      jitsiUrl
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
export const endSession = /* GraphQL */ `
  mutation EndSession($id: ID!, $status: Status!) {
    endSession(id: $id, status: $status) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      moderatorToken
      jitsiUrl
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
export const rescheduleSession = /* GraphQL */ `
  mutation RescheduleSession($id: ID!, $startDateTime: String!) {
    rescheduleSession(id: $id, startDateTime: $startDateTime) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      moderatorToken
      jitsiUrl
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
export const createStudentconversation = /* GraphQL */ `
  mutation CreateStudentconversation($input: StudentconversationInsert!) {
    createStudentconversation(input: $input) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
    }
  }
`;
export const updateStudentconversation = /* GraphQL */ `
  mutation UpdateStudentconversation(
    $id: ID!
    $input: StudentconversationUpdate!
  ) {
    updateStudentconversation(id: $id, input: $input) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
    }
  }
`;
export const deleteStudentconversation = /* GraphQL */ `
  mutation DeleteStudentconversation($id: ID!) {
    deleteStudentconversation(id: $id) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
    }
  }
`;
export const createUserconversation = /* GraphQL */ `
  mutation CreateUserconversation($input: UserconversationInsert!) {
    createUserconversation(input: $input) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
    }
  }
`;
export const updateUserconversation = /* GraphQL */ `
  mutation UpdateUserconversation($id: ID!, $input: UserconversationUpdate!) {
    updateUserconversation(id: $id, input: $input) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
    }
  }
`;
export const deleteUserconversation = /* GraphQL */ `
  mutation DeleteUserconversation($id: ID!) {
    deleteUserconversation(id: $id) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation($input: ConversationInsert!) {
    createConversation(input: $input) {
      createdAt
      updatedAt
      id
      name
      isActive
      messages {
        content
        createdAt
        updatedAt
        id
        isSent
        senderType
        conversationId
      }
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      studentconversations {
        createdAt
        updatedAt
        id
      }
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation($id: ID!, $input: ConversationUpdate!) {
    updateConversation(id: $id, input: $input) {
      createdAt
      updatedAt
      id
      name
      isActive
      messages {
        content
        createdAt
        updatedAt
        id
        isSent
        senderType
        conversationId
      }
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      studentconversations {
        createdAt
        updatedAt
        id
      }
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation($id: ID!) {
    deleteConversation(id: $id) {
      createdAt
      updatedAt
      id
      name
      isActive
      messages {
        content
        createdAt
        updatedAt
        id
        isSent
        senderType
        conversationId
      }
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      studentconversations {
        createdAt
        updatedAt
        id
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: MessageInsert!) {
    createMessage(input: $input) {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      conversationId
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($id: ID!, $input: MessageUpdate!) {
    updateMessage(id: $id, input: $input) {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      conversationId
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($id: ID!) {
    deleteMessage(id: $id) {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      conversationId
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview($input: ReviewInsert!) {
    createReview(input: $input) {
      id
      createdAt
      updatedAt
      reviewType
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      session {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      feedbackquestions {
        id
        createdAt
        updatedAt
        stars
      }
      comments
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview($id: ID!, $updatedAt: String!, $input: ReviewUpdate!) {
    updateReview(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      reviewType
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      session {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      feedbackquestions {
        id
        createdAt
        updatedAt
        stars
      }
      comments
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview($id: ID!) {
    deleteReview(id: $id) {
      id
      createdAt
      updatedAt
      reviewType
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      session {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      feedbackquestions {
        id
        createdAt
        updatedAt
        stars
      }
      comments
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer($input: AnswerInsert!) {
    createAnswer(input: $input) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      question {
        id
        createdAt
        updatedAt
        name
        name_i18n
        choices
        programType
        keywords
        summary
        summary_i18n
        description
        description_i18n
        text
        textAI
      }
      enrollment {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        selfRating
      }
      choice
      audioUri
      text
      score
      analysis
      useEnglishAi
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer($id: ID!, $updatedAt: String!, $input: AnswerUpdate!) {
    updateAnswer(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      question {
        id
        createdAt
        updatedAt
        name
        name_i18n
        choices
        programType
        keywords
        summary
        summary_i18n
        description
        description_i18n
        text
        textAI
      }
      enrollment {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        selfRating
      }
      choice
      audioUri
      text
      score
      analysis
      useEnglishAi
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer($id: ID!) {
    deleteAnswer(id: $id) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      question {
        id
        createdAt
        updatedAt
        name
        name_i18n
        choices
        programType
        keywords
        summary
        summary_i18n
        description
        description_i18n
        text
        textAI
      }
      enrollment {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        selfRating
      }
      choice
      audioUri
      text
      score
      analysis
      useEnglishAi
    }
  }
`;
export const createAvailability = /* GraphQL */ `
  mutation CreateAvailability($input: AvailabilityInsert!) {
    createAvailability(input: $input) {
      id
      createdAt
      updatedAt
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      startTime
      endTime
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      day
    }
  }
`;
export const updateAvailability = /* GraphQL */ `
  mutation UpdateAvailability(
    $id: ID!
    $updatedAt: String!
    $input: AvailabilityUpdate!
  ) {
    updateAvailability(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      startTime
      endTime
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      day
    }
  }
`;
export const deleteAvailability = /* GraphQL */ `
  mutation DeleteAvailability($id: ID!) {
    deleteAvailability(id: $id) {
      id
      createdAt
      updatedAt
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      startTime
      endTime
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      day
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse($input: CourseInsert!) {
    createCourse(input: $input) {
      id
      createdAt
      updatedAt
      name
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      plan {
        id
        createdAt
        updatedAt
      }
      lessons {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse($id: ID!, $updatedAt: String!, $input: CourseUpdate!) {
    updateCourse(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      plan {
        id
        createdAt
        updatedAt
      }
      lessons {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse($id: ID!) {
    deleteCourse(id: $id) {
      id
      createdAt
      updatedAt
      name
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      plan {
        id
        createdAt
        updatedAt
      }
      lessons {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
export const createEnrollment = /* GraphQL */ `
  mutation CreateEnrollment($input: EnrollmentInsert!) {
    createEnrollment(input: $input) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      placementStatus
      paymentStatus
      startDate
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      answer {
        id
        createdAt
        updatedAt
        choice
        audioUri
        text
        score
        analysis
        useEnglishAi
      }
      selfRating
    }
  }
`;
export const updateEnrollment = /* GraphQL */ `
  mutation UpdateEnrollment(
    $id: ID!
    $updatedAt: String!
    $input: EnrollmentUpdate!
  ) {
    updateEnrollment(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      placementStatus
      paymentStatus
      startDate
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      answer {
        id
        createdAt
        updatedAt
        choice
        audioUri
        text
        score
        analysis
        useEnglishAi
      }
      selfRating
    }
  }
`;
export const deleteEnrollment = /* GraphQL */ `
  mutation DeleteEnrollment($id: ID!) {
    deleteEnrollment(id: $id) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      placementStatus
      paymentStatus
      startDate
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      answer {
        id
        createdAt
        updatedAt
        choice
        audioUri
        text
        score
        analysis
        useEnglishAi
      }
      selfRating
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: GroupInsert!) {
    createGroup(input: $input) {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      isGroupHasInstructor
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      nextSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      perviousSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      currentSessionNumber
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      isActive
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup($id: ID!, $updatedAt: String!, $input: GroupUpdate!) {
    updateGroup(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      isGroupHasInstructor
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      nextSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      perviousSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      currentSessionNumber
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      isActive
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      isGroupHasInstructor
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      nextSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      perviousSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      currentSessionNumber
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      isActive
    }
  }
`;
export const startGroup = /* GraphQL */ `
  mutation StartGroup($groupId: String!) {
    startGroup(groupId: $groupId) {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      limit
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      isGroupHasInstructor
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      nextSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      perviousSession {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      currentSessionNumber
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      isActive
    }
  }
`;
export const createInstructor = /* GraphQL */ `
  mutation CreateInstructor($input: InstructorInsert!) {
    createInstructor(input: $input) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      name
      gender
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      promo {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      rating
      sessionsCount
      speakingLanguages
      availabilities {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
    }
  }
`;
export const updateInstructor = /* GraphQL */ `
  mutation UpdateInstructor(
    $id: ID!
    $updatedAt: String!
    $input: InstructorUpdate!
  ) {
    updateInstructor(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      name
      gender
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      promo {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      rating
      sessionsCount
      speakingLanguages
      availabilities {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
    }
  }
`;
export const deleteInstructor = /* GraphQL */ `
  mutation DeleteInstructor($id: ID!) {
    deleteInstructor(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      name
      gender
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      promo {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      rating
      sessionsCount
      speakingLanguages
      availabilities {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      slots {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      isNative
      totalRating
      totalReviews
      isActive
      status
      birthDate
    }
  }
`;
export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage($input: LanguageInsert!) {
    createLanguage(input: $input) {
      id
      createdAt
      updatedAt
      language
      country
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage(
    $id: ID!
    $updatedAt: String!
    $input: LanguageUpdate!
  ) {
    updateLanguage(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      language
      country
    }
  }
`;
export const deleteLanguage = /* GraphQL */ `
  mutation DeleteLanguage($id: ID!) {
    deleteLanguage(id: $id) {
      id
      createdAt
      updatedAt
      language
      country
    }
  }
`;
export const createLesson = /* GraphQL */ `
  mutation CreateLesson($input: LessonInsert!) {
    createLesson(input: $input) {
      id
      createdAt
      updatedAt
      name
      summary {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lessonPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      instructionsPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      tests {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      course {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson($id: ID!, $updatedAt: String!, $input: LessonUpdate!) {
    updateLesson(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      summary {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lessonPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      instructionsPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      tests {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      course {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
export const deleteLesson = /* GraphQL */ `
  mutation DeleteLesson($id: ID!) {
    deleteLesson(id: $id) {
      id
      createdAt
      updatedAt
      name
      summary {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lessonPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      instructionsPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      tests {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      course {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
export const createLevel = /* GraphQL */ `
  mutation CreateLevel($input: LevelInsert!) {
    createLevel(input: $input) {
      id
      createdAt
      updatedAt
      name
      number
      score
    }
  }
`;
export const updateLevel = /* GraphQL */ `
  mutation UpdateLevel($id: ID!, $updatedAt: String!, $input: LevelUpdate!) {
    updateLevel(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      number
      score
    }
  }
`;
export const deleteLevel = /* GraphQL */ `
  mutation DeleteLevel($id: ID!) {
    deleteLevel(id: $id) {
      id
      createdAt
      updatedAt
      name
      number
      score
    }
  }
`;
export const createAsset = /* GraphQL */ `
  mutation CreateAsset($input: AssetInsert!) {
    createAsset(input: $input) {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
    }
  }
`;
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset($id: ID!, $updatedAt: String!, $input: AssetUpdate!) {
    updateAsset(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
    }
  }
`;
export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset($id: ID!) {
    deleteAsset(id: $id) {
      id
      createdAt
      updatedAt
      type
      thumbnailUri
      fileUri
      title_i18n
      subTitle_i18n
      position
    }
  }
`;
export const createMembership = /* GraphQL */ `
  mutation CreateMembership($input: MembershipInsert!) {
    createMembership(input: $input) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      sessionPerWeek
      isFullFilled
      groups {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      discountcode {
        id
        createdAt
        updatedAt
        codeName
        valuePercentage
        isExpired
        validFrom
        expirationDate
        usage
      }
    }
  }
`;
export const updateMembership = /* GraphQL */ `
  mutation UpdateMembership(
    $id: ID!
    $updatedAt: String!
    $input: MembershipUpdate!
  ) {
    updateMembership(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      sessionPerWeek
      isFullFilled
      groups {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      discountcode {
        id
        createdAt
        updatedAt
        codeName
        valuePercentage
        isExpired
        validFrom
        expirationDate
        usage
      }
    }
  }
`;
export const deleteMembership = /* GraphQL */ `
  mutation DeleteMembership($id: ID!) {
    deleteMembership(id: $id) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      paymentStatus
      placementStatus
      isActive
      startDate
      endDate
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      sessionPerWeek
      isFullFilled
      groups {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      discountcode {
        id
        createdAt
        updatedAt
        codeName
        valuePercentage
        isExpired
        validFrom
        expirationDate
        usage
      }
    }
  }
`;
export const createPackage = /* GraphQL */ `
  mutation CreatePackage($input: PackageInsert!) {
    createPackage(input: $input) {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      price {
        id
        createdAt
        updatedAt
        name
        price
        currency
        country
      }
      description
      benefits
      benefits_i18n
      promoAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      coverAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      enrollmentPackage {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      promocodes {
        id
        createdAt
        updatedAt
        codeName
        isApplied
        sessionPerWeek
      }
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
    }
  }
`;
export const updatePackage = /* GraphQL */ `
  mutation UpdatePackage(
    $id: ID!
    $updatedAt: String!
    $input: PackageUpdate!
  ) {
    updatePackage(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      price {
        id
        createdAt
        updatedAt
        name
        price
        currency
        country
      }
      description
      benefits
      benefits_i18n
      promoAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      coverAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      enrollmentPackage {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      promocodes {
        id
        createdAt
        updatedAt
        codeName
        isApplied
        sessionPerWeek
      }
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
    }
  }
`;
export const deletePackage = /* GraphQL */ `
  mutation DeletePackage($id: ID!) {
    deletePackage(id: $id) {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      price {
        id
        createdAt
        updatedAt
        name
        price
        currency
        country
      }
      description
      benefits
      benefits_i18n
      promoAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      coverAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      details
      details_i18n
      isFree
      numberOfSessions
      description_i18n
      isNative
      isActive
      isHidden
      enrollmentPackage {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      promocodes {
        id
        createdAt
        updatedAt
        codeName
        isApplied
        sessionPerWeek
      }
      productIAP
      typePackage
      packageDuration
      discountDescription
      isRecommended
      sessionPerWeek
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan($input: PlanInsert!) {
    createPlan(input: $input) {
      id
      createdAt
      updatedAt
      d1 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d2 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d3 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d4 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d5 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d6 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d7 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d8 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d9 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d10 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d11 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d12 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d13 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d14 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d15 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d16 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d17 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d18 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d19 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d20 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d21 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d22 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d23 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d24 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d25 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d26 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d27 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d28 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d29 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d30 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d31 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d32 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d33 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d34 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d35 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d36 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d37 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d38 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d39 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d40 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d41 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d42 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d43 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d44 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d45 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d46 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d47 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d48 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d49 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d50 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d51 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d52 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d53 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d54 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d55 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d56 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d57 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d58 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d59 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d60 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d61 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d62 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d63 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d64 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d65 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d66 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d67 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d68 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d69 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d70 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d71 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d72 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d73 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d74 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d75 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d76 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d77 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d78 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d79 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d80 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d81 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d82 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d83 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d84 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d85 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d86 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d87 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d88 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d89 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d90 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan($id: ID!, $updatedAt: String!, $input: PlanUpdate!) {
    updatePlan(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      d1 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d2 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d3 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d4 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d5 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d6 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d7 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d8 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d9 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d10 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d11 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d12 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d13 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d14 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d15 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d16 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d17 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d18 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d19 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d20 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d21 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d22 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d23 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d24 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d25 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d26 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d27 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d28 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d29 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d30 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d31 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d32 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d33 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d34 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d35 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d36 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d37 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d38 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d39 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d40 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d41 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d42 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d43 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d44 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d45 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d46 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d47 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d48 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d49 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d50 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d51 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d52 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d53 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d54 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d55 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d56 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d57 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d58 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d59 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d60 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d61 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d62 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d63 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d64 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d65 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d66 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d67 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d68 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d69 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d70 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d71 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d72 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d73 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d74 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d75 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d76 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d77 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d78 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d79 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d80 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d81 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d82 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d83 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d84 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d85 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d86 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d87 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d88 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d89 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d90 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan($id: ID!) {
    deletePlan(id: $id) {
      id
      createdAt
      updatedAt
      d1 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d2 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d3 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d4 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d5 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d6 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d7 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d8 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d9 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d10 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d11 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d12 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d13 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d14 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d15 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d16 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d17 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d18 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d19 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d20 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d21 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d22 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d23 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d24 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d25 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d26 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d27 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d28 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d29 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d30 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d31 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d32 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d33 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d34 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d35 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d36 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d37 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d38 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d39 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d40 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d41 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d42 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d43 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d44 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d45 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d46 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d47 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d48 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d49 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d50 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d51 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d52 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d53 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d54 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d55 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d56 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d57 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d58 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d59 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d60 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d61 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d62 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d63 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d64 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d65 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d66 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d67 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d68 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d69 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d70 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d71 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d72 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d73 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d74 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d75 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d76 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d77 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d78 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d79 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d80 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d81 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d82 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d83 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d84 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d85 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d86 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d87 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d88 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d89 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      d90 {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
    }
  }
`;
export const createParent = /* GraphQL */ `
  mutation CreateParent($input: ParentInsert!) {
    createParent(input: $input) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      name
      gender
    }
  }
`;
export const updateParent = /* GraphQL */ `
  mutation UpdateParent($id: ID!, $updatedAt: String!, $input: ParentUpdate!) {
    updateParent(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      name
      gender
    }
  }
`;
export const deleteParent = /* GraphQL */ `
  mutation DeleteParent($id: ID!) {
    deleteParent(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      name
      gender
    }
  }
`;
export const createPrice = /* GraphQL */ `
  mutation CreatePrice($input: PriceInsert!) {
    createPrice(input: $input) {
      id
      createdAt
      updatedAt
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      name
      price
      currency
      country
    }
  }
`;
export const updatePrice = /* GraphQL */ `
  mutation UpdatePrice($id: ID!, $updatedAt: String!, $input: PriceUpdate!) {
    updatePrice(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      name
      price
      currency
      country
    }
  }
`;
export const deletePrice = /* GraphQL */ `
  mutation DeletePrice($id: ID!) {
    deletePrice(id: $id) {
      id
      createdAt
      updatedAt
      package {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      name
      price
      currency
      country
    }
  }
`;
export const createProgram = /* GraphQL */ `
  mutation CreateProgram($input: ProgramInsert!) {
    createProgram(input: $input) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      packages {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      courses {
        id
        createdAt
        updatedAt
        name
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      flag
      supportAi
      isActive
      instructors {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
    }
  }
`;
export const updateProgram = /* GraphQL */ `
  mutation UpdateProgram(
    $id: ID!
    $updatedAt: String!
    $input: ProgramUpdate!
  ) {
    updateProgram(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      packages {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      courses {
        id
        createdAt
        updatedAt
        name
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      flag
      supportAi
      isActive
      instructors {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
    }
  }
`;
export const deleteProgram = /* GraphQL */ `
  mutation DeleteProgram($id: ID!) {
    deleteProgram(id: $id) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      packages {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isActive
        isHidden
        productIAP
        typePackage
        packageDuration
        discountDescription
        isRecommended
        sessionPerWeek
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      courses {
        id
        createdAt
        updatedAt
        name
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      flag
      supportAi
      isActive
      instructors {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion($input: QuestionInsert!) {
    createQuestion(input: $input) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      correctAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      asset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      choices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $id: ID!
    $updatedAt: String!
    $input: QuestionUpdate!
  ) {
    updateQuestion(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      correctAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      asset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      choices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion($id: ID!) {
    deleteQuestion(id: $id) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      correctAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      asset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      choices
      programType
      keywords
      summary
      summary_i18n
      description
      description_i18n
      text
      textAI
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool($input: SchoolInsert!) {
    createSchool(input: $input) {
      id
      createdAt
      updatedAt
      nameArabic
      name
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      teachers {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      isActive
      classesschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool($id: ID!, $updatedAt: String!, $input: SchoolUpdate!) {
    updateSchool(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      nameArabic
      name
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      teachers {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      isActive
      classesschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool($id: ID!) {
    deleteSchool(id: $id) {
      id
      createdAt
      updatedAt
      nameArabic
      name
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      teachers {
        id
        createdAt
        updatedAt
        name
        userName
        email
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      isActive
      classesschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession($input: SessionInsert!) {
    createSession(input: $input) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      moderatorToken
      jitsiUrl
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $id: ID!
    $updatedAt: String!
    $input: SessionUpdate!
  ) {
    updateSession(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      moderatorToken
      jitsiUrl
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
export const resetSessionStatus = /* GraphQL */ `
  mutation ResetSessionStatus($id: ID!, $updatedAt: String!, $status: Status!) {
    resetSessionStatus(id: $id, updatedAt: $updatedAt, status: $status) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      moderatorToken
      jitsiUrl
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession($id: ID!) {
    deleteSession(id: $id) {
      id
      createdAt
      updatedAt
      name
      slot {
        id
        createdAt
        updatedAt
        name
        slotDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
      duration
      startDateTime
      startTime
      endTime
      status
      isRescheduled
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
      moderatorToken
      jitsiUrl
      zoomUrl
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
    }
  }
`;
export const createSlot = /* GraphQL */ `
  mutation CreateSlot($input: SlotInsert!) {
    createSlot(input: $input) {
      id
      createdAt
      updatedAt
      name
      slotDuration
      availability {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      startTime
      endTime
      status
      limit
      current
      day
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
    }
  }
`;
export const updateSlot = /* GraphQL */ `
  mutation UpdateSlot($id: ID!, $updatedAt: String!, $input: SlotUpdate!) {
    updateSlot(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
      slotDuration
      availability {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      startTime
      endTime
      status
      limit
      current
      day
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
    }
  }
`;
export const deleteSlot = /* GraphQL */ `
  mutation DeleteSlot($id: ID!) {
    deleteSlot(id: $id) {
      id
      createdAt
      updatedAt
      name
      slotDuration
      availability {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
      }
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      startTime
      endTime
      status
      limit
      current
      day
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent($input: StudentInsert!) {
    createStudent(input: $input) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      notes
      facebookProfileUrl
      enrollments {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        selfRating
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      statistic {
        id
        createdAt
        updatedAt
        scores
        averageScore
      }
      status
      assignedTo
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      studentconversations {
        createdAt
        updatedAt
        id
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      classschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      isSchoolVerfied
      placementStatus
      email
      userName
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $id: ID!
    $updatedAt: String!
    $input: StudentUpdate!
  ) {
    updateStudent(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      notes
      facebookProfileUrl
      enrollments {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        selfRating
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      statistic {
        id
        createdAt
        updatedAt
        scores
        averageScore
      }
      status
      assignedTo
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      studentconversations {
        createdAt
        updatedAt
        id
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      classschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      isSchoolVerfied
      placementStatus
      email
      userName
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent($id: ID!) {
    deleteStudent(id: $id) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        partnerAppName
      }
      schoolName
      name
      birthDate
      age
      targetAge
      gender
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      notes
      facebookProfileUrl
      enrollments {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        selfRating
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive
        startDate
        endDate
        sessionPerWeek
        isFullFilled
      }
      statistic {
        id
        createdAt
        updatedAt
        scores
        averageScore
      }
      status
      assignedTo
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        zoomUrl
      }
      studentconversations {
        createdAt
        updatedAt
        id
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      classschool {
        id
        createdAt
        updatedAt
        name
        schoolYear
      }
      isSchoolVerfied
      placementStatus
      email
      userName
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest($input: TestInsert!) {
    createTest(input: $input) {
      id
      createdAt
      updatedAt
      questions {
        id
        createdAt
        updatedAt
        name
        name_i18n
        choices
        programType
        keywords
        summary
        summary_i18n
        description
        description_i18n
        text
        textAI
      }
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      asset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
export const updateTest = /* GraphQL */ `
  mutation UpdateTest($id: ID!, $updatedAt: String!, $input: TestUpdate!) {
    updateTest(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      questions {
        id
        createdAt
        updatedAt
        name
        name_i18n
        choices
        programType
        keywords
        summary
        summary_i18n
        description
        description_i18n
        text
        textAI
      }
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      asset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
export const deleteTest = /* GraphQL */ `
  mutation DeleteTest($id: ID!) {
    deleteTest(id: $id) {
      id
      createdAt
      updatedAt
      questions {
        id
        createdAt
        updatedAt
        name
        name_i18n
        choices
        programType
        keywords
        summary
        summary_i18n
        description
        description_i18n
        text
        textAI
      }
      type
      name
      name_i18n
      description
      description_i18n
      summary
      summary_i18n
      asset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lesson {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: UserInsert!) {
    createUser(input: $input) {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      parent {
        id
        createdAt
        updatedAt
        name
        gender
      }
      admin {
        id
        createdAt
        updatedAt
        firstName
        lastName
        address
        SSN
        gender
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      deviceToken
      devicePlatform
      endPointArn
      partnerAppName
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($id: ID!, $updatedAt: String!, $input: UserUpdate!) {
    updateUser(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      parent {
        id
        createdAt
        updatedAt
        name
        gender
      }
      admin {
        id
        createdAt
        updatedAt
        firstName
        lastName
        address
        SSN
        gender
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      deviceToken
      devicePlatform
      endPointArn
      partnerAppName
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      firstName
      lastName
      parent {
        id
        createdAt
        updatedAt
        name
        gender
      }
      admin {
        id
        createdAt
        updatedAt
        firstName
        lastName
        address
        SSN
        gender
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
        status
        birthDate
      }
      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      school {
        id
        createdAt
        updatedAt
        nameArabic
        name
        isActive
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      deviceToken
      devicePlatform
      endPointArn
      partnerAppName
    }
  }
`;
