export const programs = /* GraphQL */ `
  query Programs($filter: ProgramsFilter, $limit: Int, $nextToken: String) {
    programs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
        packages {
          id
          createdAt
          updatedAt
          number
          onlyAI
          name
          name_i18n
          sessionDuration
          description_i18n
          numberOfSessions
          description
          benefits
          benefits_i18n
          details
          details_i18n
          isFree
          isActive
          typePackage
          packageDuration
        }
        test {
          id
          createdAt
          updatedAt
        }
      }
      nextToken
      scannedCount
    }
  }
`;

export const programDetails = /* GraphQL */ `
  query Programs($filter: ProgramsFilter, $limit: Int, $nextToken: String) {
    programs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        name_i18n
        isActive
        packages {
          id
          createdAt
          updatedAt
          number
          onlyAI
          name
          name_i18n
          sessionDuration
          description_i18n
          numberOfSessions
          description
          benefits
          benefits_i18n
          details
          details_i18n
          isFree
          productIAP
          isActive
          typePackage
        }
        test {
          id
          createdAt
          updatedAt
          type
          name
          name_i18n
          description
          description_i18n
          summary
          summary_i18n
        }
        courses {
          id
          createdAt
          updatedAt
          name
        }
        activities {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
          title_i18n
          subTitle_i18n
          position
        }
        records {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
          title_i18n
          subTitle_i18n
          position
        }
        flag
        supportAi
      }
      nextToken
      scannedCount
    }
  }
`;

export const programById = /* GraphQL */ `
  query Program($id: String!) {
    program(id: $id) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      packages {
        id
        name
        name_i18n
        sessionDuration
        packageDuration
        description
        description_i18n
        numberOfSessions
        benefits
        benefits_i18n
        details
        details_i18n
        onlyAI
        isFree
        isNative
        isHidden
        isActive
        productIAP
        typePackage
        sessionPerWeek
        discountDescription
        program {
          id
          flag
          name
          test {
            id
          }
        }
        price {
          price
          currency
        }
        promoAsset {
          id
          type
          thumbnailUri
          fileUri
          position
        }
        enrollmentPackage {
          id
          createdAt
          updatedAt
          number
          onlyAI
          name
          name_i18n
          sessionDuration
          description
          benefits
          benefits_i18n
          details
          details_i18n
          isFree
          numberOfSessions
          description_i18n
          isNative
          isActive
          isHidden
          productIAP
          packageDuration
        }
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      courses {
        id
        createdAt
        updatedAt
        name
        level {
          id
          name
          number
        }
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      flag
      supportAi
      isActive
    }
  }
`;
