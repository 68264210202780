import {Amplify} from 'aws-amplify';
import {getUrl, GetUrlWithPathOutput} from 'aws-amplify/storage';
import awsconfig from '../amplifyconfiguration.json';
import {getPartnerAuthToken} from "../redux/actions";
export const getFileUrlByKey = async (
  path: string,
  customBucketName?: string,
): Promise<GetUrlWithPathOutput> => {
  console.log('getFileUrlByKey', path);
  if (customBucketName) {
    if(getPartnerAuthToken()){
      Amplify.configure({
        ...awsconfig,
        auth: {
          //@ts-ignore
          type: 'CUSTOM_AUTH',
          jwtToken: getPartnerAuthToken(),
        },
        aws_appsync_authenticationType: 'AWS_LAMBDA',
        aws_user_files_s3_bucket: customBucketName,
      });
    } else {
      Amplify.configure({
        ...awsconfig,
        //@ts-ignore
        aws_user_files_s3_bucket: customBucketName,
      });
    }
  }


  return await getUrl({
    path: path,
    options: {
      validateObjectExistence: true,
      expiresIn: 3600, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
    },
  });
};
