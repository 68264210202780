import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import AddBtn from '../../assets/Icons/add-btn.svg';

import {StudentCard} from './StudentCard';
import {createEnrolmentStudents, deleteStudent} from '../../redux/actions';
import moment from 'moment';
import type {User} from '../../lib';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from '../../redux/store';


export const AllCreatedStudents = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const pendingStudents = useSelector((state:any) => state.student.pendingStudents);

  // const onPressBack = useCallback(() => {
  //   console.log('DSASDASDHERER');
  //   if (pendingStudents.length > 0) {
  //     // navigate(routNames.SELECT_PROGRAM_LEVEL_SCREEN);
  //   } else {
  //     // reset({
  //     //   index: 0,
  //     //   routes: [{name: routNames.WHO_LEARN_SCREEN}],
  //     // });
  //   }
  // }, [pendingStudents.length]);

  // useEffect(() => {
  //   setOptions({
  //     onPressLeft: () => onPressBack(),
  //   });
  // }, [setOptions, onPressBack]);

  const userState: User | null = useSelector((state:any) => state.user.user);

  useEffect(
    React.useCallback(() => {
      const startTime = moment();

      return () => {
        var end = moment(); // another date
        if (moment.duration(end.diff(startTime)).asSeconds()) {
          console.log(
            'LOGIIIITTT',
            moment.duration(end.diff(startTime)).asMinutes(),
          );

          // mixpanel.track('duration_time_user_take_in_the_screen', {
          //   userId: userState?.id,
          //   screen: 'pending all students screen',
          //   component: 'screen 5 registration',
          //   duration_minutes:
          //     moment.duration(end.diff(startTime)).asMinutes() + ' minute',
          //   duration_in_minutes: moment
          //     .duration(end.diff(startTime))
          //     .asMinutes(),
          //   duration_seconds:
          //     moment.duration(end.diff(startTime)).asSeconds() + ' seconds',
          //   duration_in_seconds: moment
          //     .duration(end.diff(startTime))
          //     .asSeconds(),
          //   userAction: 'false',
          // });
        }
      };
    }, []),
  );
// const handleDelete = (index: number) => {
//   console.log('DETE');
//   dispatch(deleteStudent(index));
// };

  return (
    <div className="container py-4 d-flex flex-column justify-content-between align-items-between min-vh-100">
      {/* <div> */}
      <div className="d-flex flex-column justify-content-center align-items-center">
      <ul className='list-unstyled'>
        {pendingStudents.map((item:any, index: any) => (
          <li key={index} className="">
            {/* <div className="studentCard">
              <h3>{item.kidName}</h3>
              <p>Age: {moment().diff(item.dateOfBirth, 'years')}</p>
              <p>Gender: {item.gender}</p>
              <button onClick={() => handleDelete(index)}>Delete</button>
            </div> */}
            <StudentCard
                 onDelete={() => dispatch(deleteStudent(index as number))}

              age={moment().diff(item.dateOfBirth, 'years')}
              Gender={item.gender}
              kidName={item.kidName}
            />
          </li>
        ))}
      </ul>
      <button onClick={() => history.push('/whoNeedToLearn')} className='bg-white border-0 d-flex justify-content-center align-items-center flex-column'>
        {/* <AddBtn /> */}
        <img src={AddBtn} className='img-fluid mb-2 w-50' alt='AddBtn' />
        <span className="text-danger">Add new student</span>
      </button>
    </div>
      {/* </div> */}

      <div className='d-flex flex-column justify-content-between align-items-center'>
        <p className='text-muted'>No more students</p>
        <button
        className='btn btn-primary rounded-2 btn-lg btn-block w-25'
          onClick={() => {
            if (pendingStudents.length > 0) {
              dispatch(createEnrolmentStudents());
              history.push('/analyzeStudent')
            }
          }}
        > Continue</button>
      </div>
    </div>
  );
};
