import React, {useEffect} from 'react';
import { useHistory   } from "react-router-dom";
import BigBoy from '../../assets/Images/standup-boy.svg';
import Boy1 from '../../assets/Icons/Man02.svg';
import Girl from '../../assets/Icons/Woman02.svg';


import {targetAge} from '../../redux/reducers/student';

import {useDispatch, useSelector} from 'react-redux'

import moment from 'moment';


import type {User} from '../../lib';

export const WhoNeedToLearn = () => {
    const history = useHistory();
  const userState: User | null = useSelector((state: any) => state.user.user);

  const onSelectAge = (targetAgee: targetAge) => {
    // redirect(routNames.STUDENT_DETAILS_SCREEN, {targetAge: targetAgee});
    console.log("targetAgee",targetAgee);
    history.push(`/studentDetails/${targetAgee}`)
  };

  return (
    <div className='container' >
      <div className='row w-100 py-3 justify-content-center align-items-center' >
        <p className='h3 text-danger'>Who wants to learn?</p>

        <div  className='row w-50 justify-content-around align-items-center'>
          <button
          className='col bg-white border-0'
            onClick={() => onSelectAge(targetAge.LESS_THAN)}
            >
            <img
            className='img-fluid w-50'
            src={Boy1}
            alt='boy image'
            //   width={normalize(110)}
            //   height={normalize(110)}
            //   style={styles.childImg}
            />
            <p >
          Age 4 to 16
            </p>
          </button>

          <button
           className='col bg-white border-0'
            onClick={() => onSelectAge(targetAge.MORE_THAN)}
            >
            <img
             src={Girl}
             className='img-fluid w-50'
             alt='girl photo'
            //   style={styles.childImg}
            //   width={normalize(110)}
            //   height={normalize(110)}
            />
            <p >
             Age 16+
            </p>
          </button>
        </div>
      </div>
      <div >
        <img  
        src={BigBoy}
        className='img-fluid'
        alt='bigboy'
        />
      </div>
    </div>
  );
};

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//   },
//   halfFirstContainer: {
//     flex: 0.5,
//   },
//   headerTxt: {
//     paddingStart: normalize(22),
//     paddingEnd: normalize(22),

//     marginBottom: normalize(20),
//     color: '#F2406A',
//     fontSize: normalize(24),
//     alignSelf: 'flex-start',
//     fontFamily: FONTS.BOLD,
//   },
//   containerChilds: {
//     marginTop: normalize(60, 'height'),
//     flexDirection: 'row',
//     justifyContent: 'center',
//   },
//   childBtn: {
//     justifyContent: 'center',
//     alignContent: 'center',
//     alignItems: 'center',
//     marginEnd: normalize(20),
//   },
//   childImg: {
//     // transform: [{rotateX: '180deg'}],
//   },
//   titleBtnTxt: {
//     fontSize: normalize(16),
//     marginTop: normalize(13, 'height'),
//     fontFamily: FONTS.MEDIUM,
//   },
//   bottomContainerChild: {
//     flex: 0.5,
//     justifyContent: 'flex-end',
//     marginStart: normalize(20),
//     paddingStart: normalize(22),
//   },
// });
