import React, {memo} from 'react';


import SmallBoy from '../../assets/Icons/Boy02.svg';
import SmallGirl from '../../assets/Icons/Girl02.svg';
import Delete from '../../assets/Icons/delete.svg';



type StudentCardProps = {
  onPress?: () => void;
  onDelete: () => void;
  kidName: string;
  Gender: string;
  age: number;
};

export const StudentCard = memo(
  ({kidName, Gender, age, onPress, onDelete}: StudentCardProps) => {
    return (
      <button  onClick={onPress} className='border border-warning rounded-4 bg-white container mb-2'>
        <div className='row justify-content-center align-items-center '>
        
            <div className='col-3'>
              {Gender === 'MALE' ? (
                // <SmallBoy width={50} height={50} />
                <img src={SmallBoy} className='img-fluid w-50' alt='SmallBoy' />
              ) : (
                // <SmallGirl width={50} height={50} />
                <img src={SmallGirl} className='img-fluid w-50' alt='SmallGirl' />
              )}
            </div>
            {/* <div > */}
            <p className="text-info col-4 mb-0">
              {kidName}
            </p>
            <p  className='col-2 mb-0'>
              {age} {' ' + 'years'}
            </p>
          {/* </div> */}
          <button onClick={onDelete} className='border-0 rounded-1 bg-white col-3  h-100'>
            {/* <Delete /> */}
            <img src={Delete} className='img-fluid' alt='Delete' />
          </button>
        </div>
      </button>
    );
  },
);
