import React from 'react';
import {useSelector} from 'react-redux';
import {Modal} from 'antd';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import Boy from '../../assets/boy.svg';
import './index.css';
import {Student} from '../../lib';

type ProgressModalType = {
  onCloseProgressModal: () => void;
};
export const ProgressModal = ({onCloseProgressModal}: ProgressModalType) => {
  const selectedStudent: Student = useSelector(
    (state: any) => state.student?.selectedStudent,
  );

  return (
    <Modal
      className="analysis-modal-wrapper"
      bodyStyle={{textAlign: 'left', marginTop: 50}}
      style={{maxHeight: '90%'}}
      width={'60%'}
      centered={true}
      title="Progress"
      open={true}
      onOk={() => {}}
      footer={null}
      onCancel={() => onCloseProgressModal()}>
      <div className="row justify-content-center progbar">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 ">
          <h3 className="bar_text_right" style={{marginBottom: 50}}>
            Your pronunciation comparing with the
            <br /> native American accent.
          </h3>

          <div style={{width: 200, height: 200}}>
            <CircularProgressbarWithChildren value={66}>
              <div style={{fontSize: 12}}>
                <span className="persent_bar">
                  {`${selectedStudent.statistic?.averageScore}%`}
                </span>{' '}
                <br></br>
                <span className="persent_text">
                  Native American <br />
                  Accent
                </span>
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <img src={Boy} style={{float: 'left', marginLeft: 20}} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 ">
          <h3 className="bar_text_right" style={{marginBottom: 50}}>
            The last 20 recording results
          </h3>
          <div
            className={'scores_wrapper'}
            style={{overflowY: 'scroll', height: 300}}>
            {selectedStudent.statistic?.scores?.map(
              (score, index: number) =>
                index < 20 && (
                  <div className="percentage_div">
                    <div className="percentage_bar">
                      <span className="persent_bar">{`${score}%`}</span>
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
