import {
  SET_PROGRAMS,
  SET_SELECTED_PROGRAM,
  SET_PACKAGE_DETAILS,
} from '../../constants';
import {Package, Program} from '../../lib';

export type ProgramState = {
  programsList: Array<Program>;
  selectedProgram: Program;
  isLoading: boolean;
  packageDetails: Package;
};

export const initialState: ProgramState = {
// @ts-ignore
  packageDetails: {
    __typename: 'Package',
  },
  programsList: [],
  // @ts-ignore
  selectedProgram: {
    __typename: 'Program',
  },
  isLoading: true,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PROGRAMS:
      return {
        ...state,
        programsList: action.payload,
        isLoading: false,
      };

    case SET_SELECTED_PROGRAM:
      return {
        ...state,
        selectedProgram: action.payload,
      };

    case SET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: action.payload,
      };

    default:
      return state;
  }
};
