import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Checked from '../../assets/Icons/checked.svg';
import PendingCheck from '../../assets/Icons/pending-check.svg';
import {StudentDetails, studentProgram} from '../../redux/reducers/student';
import { useHistory } from 'react-router-dom';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AppDispatch } from '../../redux/store';
import { SET_WAITING_STUDENT } from '../../constants';
import { fetchSelectedStudent, setPendingStudents, setSelectedStudent } from '../../redux/actions';
import { User } from '../../lib';

export const AnalyzeStudent = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const [percent, setPercent] = useState<number>(0);
  const pendingStudents = useSelector((state:any) => state.student.pendingStudents);
  const userState: User | null = useSelector((state:any) => state.user.user);
  useEffect(() => {
    console.log('CERERERERER_CRETE_NEW PIKADO',pendingStudents);
    if (percent < 100) {
      setTimeout(() => {
        let newPercent = percent + Math.floor(Math.random() * 20);
        if (newPercent !== percent) {
          if (newPercent <= 100) {
            setPercent(() => newPercent);
          } else {
            setPercent(100);
          }
        } else {
          setPercent(() => percent + 1);
        }
      }, 1200);
    } else {
      // const find = pendingStudents.find((student: StudentDetails) =>
      //   student?.studentPrograms?.find(
      //     (program: studentProgram) => program?.selfRating > 0,
      //   ),
      // );
      // if (find) {
      //   reset({
      //     index: 0,
      //     routes: [{name: routNames.ASK_MINI_TEST_SCREEN}],
      //   });
      // } else {
      //   reset({
      //     index: 0,
      //     routes: [{name: routNames.ADVERTISING_SCREEN}],
      //   });
      // }
      dispatch({type: SET_WAITING_STUDENT, payload: false});
            dispatch(setSelectedStudent(pendingStudents[0]));
            if (pendingStudents[0]?.studentconversations) {
              if (
                pendingStudents[0]?.studentconversations?.length === 0 ||
                !pendingStudents[0]?.studentconversations
              ) {
                console.log('CERERERERER_CRETE_NEW PIKADO',pendingStudents[0]);
                // dispatch(createPikadoConversation());
              }
            }
            console.log('CERERERERER_CRETE_NEW PIKADO',pendingStudents);
            dispatch(fetchSelectedStudent());
            dispatch(setPendingStudents([]));
            history.push('/home')
    }
  }, [percent]);

  return (
    <div className='container-fluid py-4'>
      <div className='d-flex flex-column justify-content-around align-items-center'>
        <p className='text-danger h4'>Building personalised curriculum for the students</p>

        <div className='w-75 d-flex flex-column justify-content-center align-items-center'>
          <CircularProgressbarWithChildren
            value={percent}
            // radius={110}
            // borderWidth={5}
            // color="#FFD252"
            // shadowColor="#F1F1F1"
            // bgColor="#fff"
            styles={{
              path: {
                stroke: "#FFD252", // Custom progress line color
              },
              trail: {
                stroke: '#d6d6d6', // Background color of the circular trail
              },
            }}
            >
            <p  className='text-danger h1 fw-bold'>{percent}%</p>
            <p className='fw-semibold'>Of your plan</p>
          </CircularProgressbarWithChildren>

          <p className='text-info h5'>
          Building personalised curriculum
          </p>
        </div>

        <div className='d-flex flex-column justify-content-center align-items-center w-75'>
          <div className='row  justify-content-center align-items-center w-75'>
            <div className='col-1 px-0'>
            </div>
            {/* {percent < 28 ? <PendingCheck /> : <Checked />} */}
            <p
            className='mb-0 col-5 px-0 text-start'
              style={
                {
                  color: percent < 28 ? '#CDCDCD' : '#000',
                }
              }>
                <img src={percent < 28 ? PendingCheck : Checked} className='img-fluid p-2 ' alt={percent < 28 ? PendingCheck : Checked} />
              Analyze students data
            </p>
          </div>
          <div  className=' row d-flex  justify-content-center align-items-center w-75'>
          <div  className='col-1 px-0'>
            </div>
            <p
            className='mb-0 col-5 px-0 text-start'
              style={
                {
                  color: percent <= 60 ? '#CDCDCD' : '#000',
                }}>
                  <img src={percent < 60 ? PendingCheck : Checked} className='img-fluid p-2' alt={percent < 60 ? PendingCheck : Checked} />
              Define the suitable exercises
            </p>
          </div>

          <div className=' row d-flex  justify-content-center align-items-center w-75'>
            {/* {percent > 95 ? <Checked /> : <PendingCheck />} */}
          <div  className='col-1 px-0'>
            </div>
            <p
            className='mb-0 col-5 px-0 text-start'
              style={
                {
                  color: percent > 95 ? '#000' : '#CDCDCD',
                }}>
            <img src={percent < 95 ? PendingCheck : Checked} className='img-fluid p-2' alt={percent < 95 ? PendingCheck : Checked} />
              Define the suitable package
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};


