import startbutton from '../../assets/startbutton.svg';
import Button from 'react-bootstrap/Button';
import microfon from '../../assets/microfon.svg';
import React from 'react';
import {AssetType, Question} from '../../lib';
import './index.css';
import BasicImageDisplayAuthS3 from '../ImageCard/BasicImageDisplayAuthS3';

type QuestionCardType = {
  question: Question;
  index: number;
  onClick: () => void;
  isBlur: boolean;
};

export const QuestionCard = ({
  question,
  index,
  onClick,
  isBlur = false,
}: QuestionCardType) => {
  return (
    <div
      // className="   col-md-6 col-lg-4 rounded-0"
      className="exercise-card col-lg-4 col-md-6 col-sm-12"
      key={index}
      id={`${question.id}`}
      onClick={() => {
        if (!isBlur) {
          onClick();
        }
      }}>
      <div
        className={`card-flyer card-question rounded-0 ${
          isBlur && 'blur'
        } position-relative  mx-2`}>
        <div className="text-box rounded-0">
          <div className="image-box rounded-0">
            <BasicImageDisplayAuthS3
              thumbnailUri={question?.asset?.thumbnailUri}
            />
            {question?.asset?.type === AssetType.VIDEO && (
              <img
                className="img-fluid rounded-0 startbutton"
                src={startbutton}
              />
            )}
          </div>
          <div className="text-container">
            <h6>{question?.name}</h6>
            <p className="fw-bolder">{question?.summary}</p>
          </div>
        </div>
        <div className="position-absolute bottom-0 end-0 w-100 px-3">
          <Button
            type="button"
            className="btn-record rounded-0 fw-bold mb-3"
            data-toggle="modal"
            data-target=".bd-example-modal-lg"
            style={{marginTop: 15}}
            onClick={() => {
              if (!isBlur) {
                onClick();
              }
            }}>
            {/* <img src={microfon} alt="" /> */}
            <span> Answer question</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
