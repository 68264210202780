export const studentsMe = /* GraphQL */ `
  query Me($cognitoUserId: String) {
    me(cognitoUserId: $cognitoUserId) {
      students {
        id
        createdAt
        updatedAt
        name
        birthDate
        age
        gender
        character
        targetAge
        memberships {
          id
          isActive
          groups {
            id
            division
            updatedAt
            isGroupHasInstructor
            instructor {
              id
              promo {
                thumbnailUri
              }
              user {
                id
                timeZone
              }
            }
            slot {
              id
              day
              startTime
              endTime
            }
            isActive
          }
          package {
            id
            createdAt
            updatedAt
            number
            onlyAI
            name
            name_i18n
            # sessionDuration
            numberOfSessions
            description
            benefits
            benefits_i18n
            details
            details_i18n
            isFree
            productIAP
            typePackage
            isNative
            packageDuration
            price {
              price
              currency
            }
          }
          createdAt
          updatedAt
          paymentStatus
          placementStatus
        }
        enrollments {
          id
          createdAt
          updatedAt
          placementStatus
          selfRating
          package {
            id
            createdAt
            updatedAt
            onlyAI
            name
            isFree
            packageDuration
            program {
              id
              name
            }
          }
          test {
            id
            createdAt
            updatedAt
          }
          paymentStatus
          startDate
        }
      }
    }
  }
`;

export const selectedStudent = /* GraphQL */ `
  query Student($id: String!) {
    student(id: $id) {
      id
      createdAt
      updatedAt
      name
      birthDate
      age
      targetAge
      gender
      notes
      facebookProfileUrl
      school {
        name
      classesschool {
        statistic {
          averageScore
        }
        teacher {
          name
          profilePicture {
            fileUri
            thumbnailUri
          }
        }
      }
    }
      level {
        id
        createdAt
        updatedAt
        name
      }
      enrollments {
        id
        createdAt
        updatedAt
        placementStatus
        paymentStatus
        startDate
        selfRating
        curriculum {
          id
          createdAt
          totalPlanPeriod
          name
        }
        level {
          id
          number
        }
        package {
          id
          onlyAI
          name
          isFree
          packageDuration
          price {
            price
            currency
          }
          program {
            id
            name
            name_i18n
            flag
            supportAi
            packages {
              id
              name
              name_i18n
              sessionDuration
              description
              description_i18n
              numberOfSessions
              benefits
              benefits_i18n
              details
              details_i18n
              onlyAI
              isFree
              isNative
              isHidden
              packageDuration
              enrollmentPackage {
                id
                createdAt
                updatedAt
                number
                onlyAI
                name
                name_i18n
                sessionDuration
                packageDuration
                description
                benefits
                benefits_i18n
                details
                details_i18n
                isFree
                numberOfSessions
                description_i18n
                isNative
                isHidden
              }
              price {
                price
                currency
              }
              promoAsset {
                id
                type
                thumbnailUri
                fileUri
                position
              }
              activities {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
                title_i18n
                subTitle_i18n
                position
              }
              records {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
                title_i18n
                subTitle_i18n
                position
              }
            }
          }
        }
        test {
          id
          createdAt
          updatedAt
        }
      }

      memberships {
        id
        isActive
        sessionPerWeek
        isFullFilled
        groups {
          id
          isActive
          updatedAt
          createdAt
          isGroupHasInstructor
          nextSession {
            id
            name
            createdAt
            updatedAt
            startDateTime
            group {
              id
              updatedAt
              isGroupHasInstructor
              instructor {
                id
              }
              isActive
            }
            instructor {
              id
              name
              promo {
                thumbnailUri
              }
              user {
                id
                timeZone
              }
            }
            slot {
              id
              day
              startTime
              endTime
            }
            duration
            jitsiUrl
            status
            startTime
            endTime
          }
          perviousSession {
            id
            createdAt
            updatedAt
            startDateTime
            status
            startTime
            endTime
            instructor {
              id
              name
              promo {
                thumbnailUri
              }
              user {
                id
                timeZone
              }
            }
            slot {
              id
              day
              startTime
              endTime
            }
          }
          currentSessionNumber
          sessions {
            id
            name
            createdAt
            startDateTime
            status
            level {
              id
              name
              number
            }
            lesson {
              id
              createdAt
              updatedAt
              name
              tests {
                type
                name
                description
                summary
                asset {
                  id
                  createdAt
                  updatedAt
                  type
                  thumbnailUri
                  fileUri
                }
                questions {
                  id
                  description
                  text
                  correctAsset {
                    fileUri
                  }
                  asset {
                    id
                    createdAt
                    updatedAt
                    type
                    thumbnailUri
                    fileUri
                  }
                }
              }
              lessonPDF {
                id
                type
                title_i18n
                subTitle_i18n
                thumbnailUri
                fileUri
              }
              summary {
                id
                createdAt
                updatedAt
                thumbnailUri
                fileUri
                title_i18n
                subTitle_i18n
                type
              }
            }
          }
        }

        package {
          id
          createdAt
          updatedAt
          number
          onlyAI
          name
          name_i18n
          packageDuration
          # sessionDuration
          numberOfSessions
          description
          benefits
          benefits_i18n
          details
          details_i18n
          isFree
          productIAP
          typePackage
          isNative
          price {
            price
            currency
          }
        }
        createdAt
        updatedAt
        paymentStatus
        placementStatus
      }
      statistic {
        id
        createdAt
        updatedAt
        scores
        averageScore
      }
      status
      assignedTo

      studentconversations {
        id
        createdAt
        updatedAt
        conversation {
          id
          name
          isActive
          createdAt
          updatedAt
          userconversations {
            id
            createdAt
            updatedAt
            user {
              id
              instructor {
                id
                name
                promo {
                  thumbnailUri
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const messagesByConversationId = /* GraphQL */ `
  query MessagesByConversationId(
    $conversationId: ID!
    $scannedCount: Int
    $nextToken: String
  ) {
    messagesByConversationId(
      conversationId: $conversationId
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        content
        createdAt
        updatedAt
        conversationId
        isSent
        senderType
        content
        student {
          id
          name
          gender
        }
        user {
          id
          instructor {
            id
            name
          }
        }
      }
      nextToken
      scannedCount
    }
  }
`;

export const admins = /* GraphQL */ `
  query Admins($filter: AdminFilter, $scannedCount: Int, $nextToken: String) {
    admins(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        firstName
        lastName
        address
        SSN
        gender
        user {
          id
        }
        isActive
      }
      nextToken
      scannedCount
    }
  }
`;
