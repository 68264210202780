import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Loader,
  ProgressModal,
  QuestionCard,
  TestCard,
  TestModal,
} from '../../components';

import './HomePage.scss';

import {ExerciseModal} from '../../components/ExerciseModal';
import {
  AssetType,
  ProgramType,
  QUESTION_TYPE,
  Question,
  Status,
  Test,
} from '../../lib';
import {
  doClearTest,
  doSelectQuestion,
  doSelectTest,
  generateRandomExBasedOnWeakSyllables,
} from '../../redux/actions';

import Title from 'antd/es/typography/Title';
import moment from 'moment';
import ResponsiveAppBar from '../../components/Appbar';
import FooterBar from '../../components/Footerbar';
import ReportsHeader from '../../components/ReportsHeader';

const openAIQuestionAsset: Question = {
  id: '-OPEN-AI',
  createdAt: 'Node.generateCreatedAt()',
  updatedAt: 'Node.generateUpdatedAt()',
  name: 'Daily Reading Paragraph',
  name_i18n: '',
  programType: ProgramType.LANGUAGE,
  summary:
    'Press the below button to generate a reading paragraph depends on your repeated syllables mistakes.',
  summary_i18n: '',
  description:
    'Please press the recording button and read the below sentence in a clear voice.',
  description_i18n: '',
  text: 'selectedSentence',
  asset: {
    __typename: 'Asset',
    id: 'adsss',
    createdAt: '',
    updatedAt: '',
    type: AssetType.PHOTO,
    thumbnailUri:
      'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/photo/337778904_1074114464030879_8325695208240193271_n.jpg',
    fileUri:
      'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/photo/337778904_1074114464030879_8325695208240193271_n.jpg',
    title_i18n: '',
    subTitle_i18n: '',
    // position?: number ,
  },
  choices: [],
  keywords: [],
  textAI: 'selectedSentence?.toUpperCase()',
  type: QUESTION_TYPE.READ_SENTENCE,
  __typename: 'Question',
};

export const HomePage = () => {
  const dispatch = useDispatch();
  const teacherPhotoRef = useRef<HTMLDivElement>(null);

  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const todayRandomExercise: Question | null = useSelector(
    (state: any) => state.enrollment.todayRandomExercise,
  );
  const selectedStudent = useSelector(
    (state: any) => state.student.selectedStudent,
  );

  const studentsDetails = useSelector(
    (state: any) => state.student.studentsDetails,
  );

  const [isError, setIsError] = useState<boolean>(false);

  const [isProgressModalVisible, setIsProgressModalVisible] =
    useState<boolean>(false);

  const studentsPlanMap = useSelector(
    (state: any) => state.enrollment.studentPlan,
  );
  const [openExerciseModal, setOpenExerciseModal] = useState<boolean>(false);
  const [openTestModal, setOpenTestModal] = useState<boolean>(false);

  const selectedEnrollment = useSelector(
    (state: any) => state.enrollment.selectedEnrollment,
  );

  useEffect(() => {
    isEnrollmentReady();
    if (teacherPhotoRef?.current) {
      let photo =
        selectedStudent?.school != null &&
        selectedStudent.school?.classesschool?.[0]?.teacher?.profilePicture
          ?.fileUri != undefined
          ? selectedStudent.school.classesschool[0].teacher.profilePicture
              .fileUri
          : require('../../assets/profile-icon.jpg');
      teacherPhotoRef.current.style.backgroundImage = `url(${photo})`;
      teacherPhotoRef.current.style.backgroundSize = 'cover';
      teacherPhotoRef.current.style.backgroundPosition = 'center';
    }
  }, [selectedEnrollment]);

  const isEnrollmentReady = () => {
    if (Object.keys(studentsPlanMap)?.length !== 0 && selectedStudent) {
      return true;
    } else if (
      Object?.keys(studentsPlanMap)?.length !== 0 &&
      selectedStudent &&
      selectedEnrollment?.package.isFree
    ) {
      return true;
    }
  };

  const renderCard = (test: Test, index: number) => {
    if (test?.questions?.length! > 1 && test?.type !== 'QUIZ') {
      return (
        <TestCard
          //@ts-ignore
          test={test}
          index={index}
          key={`selectedMapId_${index}`}
          //@ts-ignore
          isBlur={
            (selectedEnrollment?.package?.isFree ||
              selectedEnrollment.paymentStatus !== Status.DONE) &&
            index > 1
              ? true
              : false
          }
          onClick={() => {
            //@ts-ignore
            dispatch(doSelectTest(test));
            setOpenTestModal(true);
          }}
        />
      );
    } else {
      return (
        <div
          className=""
          style={{display: 'contents'}}
          key={`selectedMapId_${index}`}>
          <QuestionCard
            //@ts-ignore
            isBlur={
              (selectedEnrollment?.package?.isFree ||
                selectedEnrollment.paymentStatus !== Status.DONE) &&
              index > 0
                ? true
                : false
            }
            //@ts-ignore
            question={test}
            index={index}
            onClick={() => {
              //@ts-ignore
              if (test?.questions?.length === 1) {
                //TODO: Fix questions and tests
                //@ts-ignore
                dispatch(doSelectQuestion(test.questions[0]));
              } else {
                //@ts-ignore
                dispatch(doSelectQuestion(test));
              }
              setOpenExerciseModal(true);
            }}
          />
        </div>
      );
    }
  };

  const renderTodayPlan = () => {
    const items = studentsPlanMap[selectedStudent?.id];
    if (!items) return null;
    const videoItems = items
      ?.filter((item: Test) => item.asset.type === 'VIDEO')
      .sort((a: Test, b: Test) => (a?.name ?? '').localeCompare(b?.name ?? ''));
    const otherItems = items
      ?.filter((item: Test) => item.asset.type !== 'VIDEO')
      .sort((a: Test, b: Test) => (a?.name ?? '').localeCompare(b?.name ?? ''));
    return [...videoItems, ...otherItems].map((test: Test, index: number) => {
      return renderCard(test, index);
    });
  };

  return (
    <div className="min-vh-100 d-flex flex-column">
      {!openExerciseModal && !openTestModal && <ResponsiveAppBar />}
      {/* <div className            = "reportPageContainer"> */}
      <div className="flex-grow-1">
        <ReportsHeader selectedStudent={selectedStudent} />

        <div id="cards_landscape_wrap-2">
          <div className="container">
            <div className="row gx-6 justify-content-center justify-content-md-between align-items-center mx-2">
              {isEnrollmentReady() && !isLoading && (
                <QuestionCard
                  //@ts-ignore
                  isBlur={
                    selectedEnrollment?.package?.isFree ||
                    selectedEnrollment.paymentStatus !== Status.DONE
                      ? true
                      : false
                  }
                  //@ts-ignore
                  question={openAIQuestionAsset}
                  index={10}
                  onClick={async () => {
                    const createdAt = moment(todayRandomExercise?.createdAt);
                    const today = moment();
                    // today.add(1, 'days');
                    if (
                      !todayRandomExercise ||
                      !today?.isSame(createdAt, 'days')
                    ) {
                      //@ts-ignore
                      await dispatch(generateRandomExBasedOnWeakSyllables());
                      // if (todayRandomExercise) {
                      setOpenExerciseModal(true);
                      //}
                    } else {
                      //@ts-ignore
                      dispatch(doSelectQuestion(todayRandomExercise));
                      setOpenExerciseModal(true);
                    }
                  }}
                />
              )}
              {isEnrollmentReady() && renderTodayPlan()}
            </div>
          </div>
          {openExerciseModal && (
            <ExerciseModal
              closeModal={() => {
                //@ts-ignore
                dispatch(doClearTest());

                setOpenExerciseModal(false);
              }}
            />
          )}

          {openTestModal && (
            <TestModal
              onQuestionsClick={() => {
                setOpenExerciseModal(true);
                setOpenTestModal(false);
              }}
              closeModal={() => {
                //@ts-ignore
                dispatch(doClearTest());
                setOpenTestModal(false);
              }}
            />
          )}
        </div>
      </div>
      {isProgressModalVisible && (
        <ProgressModal
          onCloseProgressModal={() => setIsProgressModalVisible(false)}
        />
      )}
      {isLoading ? <Loader title={'Loading...'} /> : null}
      {isError && <Title>Exercises fetching error, try again ⚠️</Title>}
      {isEnrollmentReady() && !isError && (
        <div className="mt-auto">
          <FooterBar />
        </div>
      )}
    </div>
  );
};
