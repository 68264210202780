export const packageById = /* GraphQL */ `
  query Package($id: String!) {
    package(id: $id) {
      id
      createdAt
      updatedAt
      number
      onlyAI
      name
      name_i18n
      sessionDuration
      packageDuration
      numberOfSessions
      description_i18n
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
      }
      price {
        id
        createdAt
        updatedAt
        name
        price
        currency
        country
      }
      description
      benefits
      benefits_i18n
      typePackage
      promoAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      coverAsset {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      details
      details_i18n
      isFree
      isNative
      isHidden
      enrollmentPackage {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        numberOfSessions
        description_i18n
        isNative
        isHidden
        packageDuration
      }
    }
  }
`;
