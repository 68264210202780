import {IEnrollmentRepository} from './interfaces';
import {Enrollment, Status} from '../API';
import * as customQueries from '../customQueries';
import {
  createEnrollment,
  getRandomExerciseWeakSyllables,
  updateEnrollment,
} from '../CustomMutations/enrollment';
import {getPartnerAuthToken} from '../../redux/actions';
import {generateClient, GraphQLResult} from 'aws-amplify/api';

const client = generateClient();
export class EnrollmentRepository implements IEnrollmentRepository {
  constructor() {}

  async getStudentRandomExercise(studentId: string): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: getRandomExerciseWeakSyllables,
      variables: {
        studentId,
      },
      authToken: getPartnerAuthToken(),
    });

    console.log('response->getStudentRandomExercise', response);

    return response?.data?.getRandomExerciseWeakSyllables;
  }
  async levelUpEnrollment(
    enrollmentId: string,
    levelId: string,
    updatedAt: string,
  ): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: updateEnrollment,
      variables: {
        id: enrollmentId,
        updatedAt: updatedAt,
        input: {
          levelId,
        },
      },
      authToken: getPartnerAuthToken(),
    });

    console.log('response->levelUpEnrollment', response);

    return response;
  }
  getStudentEnrollments(studentId: string): Promise<Enrollment | any> {
    /*return new Promise<Enrollment>((resolve) => {
      resolve(exampleEnrollments);
    });*/
    return Promise.reject('Please implement the function');
  }

  async createEnrollment(inputs: {
    studentId: string;
    packageId: string;
    testId: string;
    placementStatus: string;
    paymentStatus: string;
    selfRating: number;
    levelId: string;
  }): Promise<any> {
    console.log('enrollment->', inputs);

    let response: GraphQLResult<any> = await client.graphql({
      query: createEnrollment,
      variables: {
        input: {
          studentId: inputs.studentId,
          packageId: inputs.packageId,
          testId: inputs.testId,
          placementStatus: inputs.placementStatus,
          paymentStatus: inputs.paymentStatus,
          selfRating: inputs.selfRating,
          startDate: new Date().toISOString().split('T')[0],
          levelId: inputs.levelId,
        },
      },
      authToken: getPartnerAuthToken(),
    });

    console.log('response->createEnrollment', response);

    return response.data.createEnrollment;
  }

  async updateEnrollment(_inputs: {
    enrollmentId: string;
    packageId: string;
    startDate: string;
    paymentStatus: Status;
    updatedAt: string;
  }): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: updateEnrollment,
      variables: {
        id: _inputs.enrollmentId,
        updatedAt: _inputs.updatedAt,
        input: {
          packageId: _inputs.packageId,
          startDate: _inputs.startDate,
          paymentStatus: _inputs.paymentStatus,
        },
      },
      authToken: getPartnerAuthToken(),
    });

    console.log('response->createEnrollment', response);
    return response;
  }

  async updatePaymentStatus(
    enrollmentId: string,
    status: Status,
    updatedAt: string,
  ): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: customQueries.updateEnrollmentCustom,
      variables: {
        id: enrollmentId,
        updatedAt: updatedAt,
        input: {
          paymentStatus: status,
        },
      },
      authToken: getPartnerAuthToken(),
    });

    return response;
  }

  async updateStartDate(
    enrollmentId: string,
    startDate: string,
    updatedAt: string,
  ): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: customQueries.updateEnrollmentCustom,
      variables: {
        id: enrollmentId,
        updatedAt: updatedAt,
        input: {
          startDate: startDate,
          placementStatus: 'TODO',
        },
      },
      authToken: getPartnerAuthToken(),
    });
    return response;
  }

  async updatePlacementTestStatus(
    enrollmentId: string,
    status: Status,
    updatedAt: string,
  ): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: customQueries.updateEnrollmentCustom,
      variables: {
        id: enrollmentId,
        updatedAt: updatedAt,
        input: {
          placementStatus: status,
        },
      },
      authToken: getPartnerAuthToken(),
    });
    return response;
  }
}
