import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {IntercomProps, useIntercom} from 'react-use-intercom';

function InterCom() {
  const {boot, shutdown, hide, show, update, hardShutdown} = useIntercom();
  const auth = useSelector((state: any) => state.auth);
  const user = useSelector((state: any) => state.user);
  useEffect(() => {
    if (auth?.isLoggedIn && auth?.user?.attributes?.email && user?.user?.id) {
      const bootProps: IntercomProps = {
        email: auth?.user?.attributes?.email,
        userId: user?.user?.id,
        name: '',
      };

      boot(bootProps);
    } else {
      hardShutdown();
    }
    return () => {};
  }, [
    auth?.isLoggedIn,
    auth?.user?.attributes?.email,
    boot,
    hardShutdown,
    user?.user?.id,
  ]);

  console.log('user', auth.isLoggedIn);
  return <div />;
}

export default InterCom;
