import storage from 'redux-persist/lib/storage';
import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';

import appState from './appState';
import user from './user';
import auth from './auth';
import student from './student';
import program from './program';
import test from './test';
import enrollment from './enrollment';
import questionReducers from './questionReducers';

const appStatePersistConfig = {
  key: 'appState',
  storage: storage,
  whitelist: [],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['user', 'isLoggedIn', 'isSignedUp', 'partnerUser'],
};

const studentPersistConfig = {
  key: 'student',
  storage: storage,
  whitelist: ['selectedStudent', 'pendingStudents'],
};

const userReducerPersistConfig = {
  key: 'user',
  storage: storage,
  whitelist: ['user'],
};

const programPersistConfig = {
  key: 'program',
  storage: storage,
  whitelist: ['selectedProgram'],
};

const testPersistConfig = {
  key: 'test',
  storage: storage,
  whitelist: [''],
};

const enrollmentPersistConfig = {
  key: 'enrollment',
  storage: storage,
  whitelist: ['todayRandomExercise'],
};

export const rootReducer = combineReducers({
  appState: persistReducer(appStatePersistConfig, appState),
  auth: persistReducer(authPersistConfig, auth),
  user: persistReducer(userReducerPersistConfig, user),
  student: persistReducer(studentPersistConfig, student),
  program: persistReducer(programPersistConfig, program),
  test: persistReducer(testPersistConfig, test),
  questionReducers,
  enrollment: persistReducer(enrollmentPersistConfig, enrollment),
});
