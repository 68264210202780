import React, {useEffect, useState} from 'react';
import {getFileUrlByKey} from '../../utils/Storage';

type ProfilePictureCardProps = {
  thumbnailUri: string | undefined | null;
  style?: any;
};

export default function BasicImageDisplayAuthS3({
  thumbnailUri,
  style,
}: ProfilePictureCardProps) {
  const [img, setImg] = useState<string | undefined | null>(thumbnailUri);

  const fetchImage = async () => {
    if (thumbnailUri) {
      const pathContent = thumbnailUri?.split('/');
      console.log('pathContent', pathContent);

      const bucketName = pathContent[3];
      let uri = thumbnailUri;
      console.log('bucketName11', bucketName);

      try {
        const pathVideo = thumbnailUri?.split('.pikado.io/');
        console.log('thumbnailUri00', pathVideo);

        const videoProfileUrl = await getFileUrlByKey(pathVideo[1], bucketName);
        uri = videoProfileUrl.url.toJSON();
        setImg(uri);
        // console.log('uri33', uri);
      } catch (error) {
        console.error('ERRRR:->', error);
      }
    }
  };

  return (
    <img
      onError={fetchImage}
      className="img-fluid"
      //@ts-ignore
      src={img}
      alt="loading..."
    />
  );
}
