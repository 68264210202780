import {
  LAST_QUESTION,
  NEXT_QUESTION,
  RESET_TEST,
  SELECT_QUESTION,
  SELECT_TEST,
  LOADING_TEST,
  SET_CURRENT_TEST,
} from '../../constants';
import {store} from '../store';
import {ProgramService} from '../../lib/services/ProgramService';
import {isLoadingRequest} from './appState';
import {Test} from '../../lib';

export const doSelectTest = (test: any) => async (dispatch: any) => {
  console.log('doSelectTets test:', test);
  console.log('selected from action test->', test.questions[0]);
  dispatch({type: SELECT_TEST, payload: test});
  dispatch({type: SELECT_QUESTION, payload: test.questions[0]});
};

export const doNextQuestion = () => async (dispatch: any) => {
  let testState = store.getState().test;
  let test = testState.currentTest;
  console.log('test:->', test);
  console.log('testState:->', testState);

  let selectedQuestionIndex = testState?.selectedQuestionIndex;
  let newQuestionIndex = selectedQuestionIndex + 1;
  //@ts-ignore
  if (newQuestionIndex <= test.questions.length - 2) {
    dispatch({type: NEXT_QUESTION, payload: newQuestionIndex});
    dispatch({
      type: SELECT_QUESTION,
      //@ts-ignore
      payload: test.questions[newQuestionIndex],
    });
  } else {
    console.log('selectedQuestionIndex in false ->', newQuestionIndex);
    dispatch({
      type: SELECT_QUESTION,
      //@ts-ignore
      payload: test.questions[test.questions.length - 1],
    });
    dispatch(doLastQuestion());
  }
};

export const doClearTest = () => async (dispatch: any) => {
  dispatch({type: RESET_TEST});
};

export const doLastQuestion = () => async (dispatch: any) => {
  dispatch({type: LAST_QUESTION});
};

const programService = new ProgramService();

const isLoading = (isLoading: boolean) => {
  return {
    type: LOADING_TEST,
    payload: {isLoading},
  };
};

export const setCurrentTest = (test: Test | null) => {
  return {
    type: SET_CURRENT_TEST,
    payload: test,
  };
};

export const getTest = (id: string) => {
  //@ts-ignore
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    try {
      const test = await programService.getTestById(id);
      dispatch(setCurrentTest(test));
      dispatch(isLoading(false));
    } catch (error: any) {
      console.log('ERR', error);
      dispatch(isLoading(false));
      dispatch(isLoadingRequest(false, error));
    }
  };
};
