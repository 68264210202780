import {IAuthenticationService} from './interfaces';
import {AuthenticationRepository} from '../repositories';

export class AuthenticationService implements IAuthenticationService {
  authRepository: AuthenticationRepository;

  constructor() {
    this.authRepository = new AuthenticationRepository();
  }

  async signUp(email: string, password: string, phoneNumber: string): Promise<any> {
    return await this.authRepository.signUp(email, password, phoneNumber);
  }

  async signIn(email: string, password: string): Promise<any> {
    return await this.authRepository.signIn(email, password);
  }

  async validatePartnerAuthUser(
    partnerAppName: string,
    studentId: string,
  ): Promise<any> {
    return await this.authRepository.validatePartnerAuth(
      partnerAppName,
      studentId,
    );
  }

  async signOut(): Promise<any> {
    return await this.authRepository.signOut();
  }

  forgetPassword(email: string): Promise<any> {
    return Promise.resolve(undefined);
  }

  async updateCognitoUser(phoneNumber?: string, email?: string): Promise<any> {
    let userAttr: any = {};

    if (phoneNumber) {
      userAttr.phone_number = phoneNumber;
    }

    if (email) {
      userAttr.email = email;
    }

    await this.authRepository.updateCognitoUser(userAttr);

    if (phoneNumber) {
      return await this.sendVerficationCodeUserPhoneNumber();
    }

    if (email) {
      return await this.sendVerficationCodeUserEmail();
    }
  }

  async sendVerficationCodeUserEmail(): Promise<any> {
    return await this.authRepository.sendVerficationCodeUserEmail();
  }

  async sendVerficationCodeUserPhoneNumber(): Promise<any> {
    return await this.authRepository.sendVerficationCodeUserPhoneNumber();
  }

}
