import axios from 'axios';

import {getPartnerAuthToken} from '../../redux/actions';
import {IStorageRepository} from './interfaces';
import {generateSignedUrlUploadAudioFile} from '../graphql/mutations';
import {generateWordTospeech} from '../../graphql/mutations';
import {PikaDoCharacters} from '../API';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
import {uploadData} from 'aws-amplify/storage';
const client = generateClient();

export const ObjectCannedACL = {
  authenticated_read: 'authenticated-read',
  aws_exec_read: 'aws-exec-read',
  bucket_owner_full_control: 'bucket-owner-full-control',
  bucket_owner_read: 'bucket-owner-read',
  private: 'private',
  public_read: 'public-read',
  public_read_write: 'public-read-write',
};
export class StorageRepository implements IStorageRepository {
  constructor() {}

  async uploadAudioFile(file: any, fileName: string): Promise<any> {
    console.log('fileName->', fileName);
    //unauthorized with cognitio -> excute the next if condition
    if (getPartnerAuthToken()) {
      let resp: GraphQLResult<any> = await client.graphql({
        query: generateSignedUrlUploadAudioFile,

        variables: {
          input: {
            fileName,
            ACL: ObjectCannedACL.bucket_owner_read,
          },
        },
        authToken: getPartnerAuthToken(),
      });

      const headers = {
        'Content-Type': 'audio/wav',
      };

      await axios
        //@ts-ignore
        .put(resp?.data?.generateSignedUrlUploadAudioFile, file.blob, {
          headers: headers,
        })
        .catch((error) => {
          throw new Error(
            'Error in uploading audio record using generateSignedUrlUploadAudioFile: ' +
              error,
          );
        });

      return {path: fileName};
    } else {
      const result = await uploadData({
        path: fileName,
        data: file.blob,
        options: {
          contentType: 'audio/aac',
        },
      }).result;
      console.log('Key from Response: ', result.path);
      return result;
    }
  }

  async generateWordToSpeech(
    voiceId: PikaDoCharacters,
    text: string,
  ): Promise<any> {
    let resp: GraphQLResult<any> = await client.graphql({
      query: generateWordTospeech,

      variables: {
        input: {
          voiceId,
          text,
        },
      },
      authToken: getPartnerAuthToken(),
    });

    console.log(
      'resp.data.generateWordTospeech',
      resp.data.generateWordTospeech,
    );
    return resp.data?.generateWordTospeech;
  }
}
