import { Function } from 'lodash';
import React, {useState, useMemo, Dispatch, SetStateAction, HTMLAttributes} from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { validateRequireFields } from '../../utils/stringUtils';
import Swal from 'sweetalert2';

interface CountryPickerProps extends HTMLAttributes<HTMLDivElement> {
  setCountry: Dispatch<SetStateAction<string>>;
  setIsCountryValid: Dispatch<SetStateAction<boolean|undefined>>;
}


export const CountryPicker: React.FC<CountryPickerProps> = ({setCountry,setIsCountryValid}) => {
  const [value, setValue] = useState<{label:string, value:string}|undefined>();
  const options = useMemo(() => countryList().getData(), []);
/**
 * Validates if a country has been selected.
 * If no country is selected, displays a warning popup and sets the state isCountryValid to false.
 * If a country is selected, sets the state isCountryValid to true.
 */
const validateCountry = (e:any) => {
  if (value ===undefined || !validateRequireFields(value.label)) {
    Swal.fire('Please select a country');
    setIsCountryValid(false);
  }else{
    setIsCountryValid(true);
  }
};
  const changeHandler = (value: any) => {
    setValue(value);
    setCountry(value.label)
  };
  return (
      <Select
          //@ts-ignore
        styles={{backgroundColor: 'red'}}
        className="form-control"
        //@ts-ignore
        options={options}
        value={value}
        onChange={changeHandler}
        onBlur={validateCountry}
      />
  );
};
