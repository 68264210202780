import {IS_LOADING_REQUEST, IS_LOADING_REQUEST_LOGIN} from '../../constants';

const initialState: AppState = {
  error: null,
  isLoading: false,
  isLoadingLogIn: false,
};

export type AppState = {
  isLoading: boolean;
  isLoadingLogIn: boolean;
  error: string | null;
};

const appState = (state = initialState, action: any) => {
  switch (action.type) {
    case IS_LOADING_REQUEST:
      return {
        ...state,
        isLoading: action?.payload?.isLoading,
        error: action?.payload?.error || null,
      };
    case IS_LOADING_REQUEST_LOGIN:
      return {
        ...state,
        isLoadingLogIn: action?.payload?.isLoading,
        error: action?.payload?.error || null,
      };

    default:
      return state;
  }
};
export default appState;
