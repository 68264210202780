import {IStatisticRepository} from './interfaces';
import {generateClient, GraphQLResult} from 'aws-amplify/api';

import {generateFullReportAsString} from '../../graphql/mutations';
import {
  customGetSyllablesByLetters,
  fullReport,
} from '../CustomQueries/statistic';
import {getPartnerAuthToken} from '../../redux/actions';

const client = generateClient();
export class StatisticRepository implements IStatisticRepository {
  constructor() {}

  async generateFullReportAsString(studentId: string): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: generateFullReportAsString,
      variables: {
        studentId: studentId,
      },
      authToken: getPartnerAuthToken(),
    });

    console.log('response->', response);
    return response;
  }

  async getFullReport(statisticId: string): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: fullReport,
      variables: {
        id: statisticId,
      },
      authToken: getPartnerAuthToken(),
    });

    console.log('response->', response);
    return response.data.statistic;
  }

  async getSyllablesByLetters(lettersList: Array<string>): Promise<any> {
    if (lettersList && lettersList.length !== 0) {
      let response: GraphQLResult<any> = await client.graphql({
        query: customGetSyllablesByLetters,
        variables: {
          lettersList: lettersList,
        },
        authToken: getPartnerAuthToken(),
      });

      return response.data.getSyllablesByLetters.items;
    }
  }
}
