import {Image, Typography} from 'antd';
import {Student, User} from '../../lib';
import {useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import Cup from '../../assets/cup.png';
import ClassRank from '../../assets/classRank.png';
import gameBounus from '../../assets/audio/game-bonus-144751.mp3';

export default function ReportHeader({
  selectedStudent,
}: Readonly<{selectedStudent: Student}>) {
  const preformanceRef = useRef<HTMLDivElement>(null);
  const teacherPhotoRef = useRef<HTMLDivElement>(null);
  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const [score, setScore] = useState<number>(
    selectedStudent?.statistic?.averageScore
      ? selectedStudent?.statistic?.averageScore
      : 0,
  );
  const user: User = useSelector((state: any) => state.user.user);
  useEffect(() => {
    if (teacherPhotoRef?.current) {
      let photo =
        selectedStudent?.school?.classesschool?.[0]?.teacher?.profilePicture
          ?.fileUri && selectedStudent?.school != null
          ? selectedStudent.school.classesschool[0].teacher.profilePicture
              .fileUri
          : require('../../assets/profile-icon.jpg');
      teacherPhotoRef.current.style.backgroundImage = `url(${photo})`;
      teacherPhotoRef.current.style.backgroundSize = 'cover';
      teacherPhotoRef.current.style.backgroundPosition = 'center';
    }
  }, []);

  useEffect(() => {
    try {
      if (!isLoading) {
        const startTime = Date.now();
        let animationFrameId: number;

        const audio = new Audio(gameBounus);
        //  audio.play();
        const updateCount = () => {
          const elapsedTime = Date.now() - startTime;
          const progress = Math.min(elapsedTime / 2000, 1);
          const currentCount = Math.floor(progress * (score - 0) + 0);

          setScore(currentCount);

          if (progress < 1) {
            animationFrameId = requestAnimationFrame(updateCount);
          }
        };

        animationFrameId = requestAnimationFrame(updateCount);

        return () => {
          audio.pause();
          cancelAnimationFrame(animationFrameId);
        };
      }
    } catch (error) {
      console.log('Error in Score animation', error);
    }
  }, [0, selectedStudent?.statistic?.averageScore, 2000]);

  const gradeScores = [90, 85, 80, 75, 70, 65, 60, 55, 50, 40];
  const grades = ['A+', 'A', 'A-', 'B+', 'B', 'C+', 'C', 'D+', 'D', 'E', 'F'];

  const getGradeByScore = () => {
    const score = selectedStudent?.statistic?.averageScore
      ? selectedStudent?.statistic?.averageScore
      : 0;
    return (
      grades[gradeScores.findIndex((gradeScore) => score >= gradeScore)] || 'F'
    );
  };
  const gradeColors = [
    '#C3E56E',
    '#FFD252',
    '#40B2F2',
    '#EF426B',
    '#F772FA',
    '#D7D7D7',
    '#B4B4B4',
    '#808080',
    '#2F2F2F',
    '#D9D9D9',
    '#D9D9D9',
  ];
  const gradeColorMap: {[key: number]: string} = gradeScores.reduce(
    (map, score, index) => ({
      ...map,
      [score]: gradeColors[index],
    }),
    {},
  );
  const getGradeColor = () => {
    const score = selectedStudent?.statistic?.averageScore ?? 0;
    const color = gradeColorMap[score] ?? '#D9D9D9';
    if (preformanceRef.current) {
      preformanceRef.current.style.backgroundColor =
        gradeColorMap[score] ?? '#D9D9D9';
    }
    return color;
  };

  return (
    <div className="container-fluid mx-0 px-0">
      <div className="row mx-auto w-100  mx-0 px-0 gx-2 flex-grow-1">
        {/* Teacher photo, name and school name */}
        <div className="col-12 col-sm-6 col-lg-3 px-0 d-flex align-items-center border-top-0 border border-1 border-muted ">
          <div className="col-3" id="teacherPhotoRef" ref={teacherPhotoRef}>
            <div className="ratio ratio-1x1">
              <Image preview={false} />
            </div>
          </div>
          <div className="col-9 d-flex flex-column align-items-start justify-content-center ps-3">
            <p className="fw-bold fs-5 w-100 text-truncate mb-0 ">
              Mr.
              {selectedStudent?.school?.classesschool?.[0]?.teacher?.name ?? ''}
            </p>
            <p className="text-custom-grey fs-6 mb-0">
              {selectedStudent?.school?.name ?? ''}
            </p>
          </div>
        </div>
        {/* Total performance */}
        <div className="col-12 col-sm-6 col-lg-3 d-flex align-items-center  border-top-0  border border-1 border-muted ps-0">
          <div
            className="col-3 h-100"
            style={{
              backgroundColor: getGradeColor(),
            }}>
            <div
              className="position-relative"
              style={{
                aspectRatio: '1/1',
              }}>
              <p
                style={{
                  color: '#FFFFFF',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: 25,
                  fontFamily: 'Arial Rounded MT Bold',
                }}>
                {getGradeByScore()}
              </p>
            </div>
          </div>
          <div className="col-9 ps-3 d-flex flex-column align-items-start justify-content-center  ">
            <p className="mb-0 fw-bolder text-custom-grey fs-5 w-100 text-truncate">
              PERFORMANCE
            </p>
            <p
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                fontSize: 30,
                color: '#40B2F2',
                marginBottom: 0,
              }}>
              {selectedStudent?.statistic?.averageScore
                ? selectedStudent?.statistic?.averageScore
                : 0}
              %
            </p>
          </div>
        </div>
        {/* Score */}
        <div className="col-12 col-sm-6 col-lg-3 d-flex align-items-center border-start-0 border-top-0 border border-1 border-muted">
          <div className="col-3 ">
            <Image
              preview={false}
              className="img-fluid p-2"
              src={Cup}
              alt="cup logo"
            />
          </div>
          <div className="col-9 ps-3 d-flex flex-column align-items-start justify-content-center  ">
            <p className="fw-bolder mb-0 text-custom-grey fs-5 w-100 text-truncate">
              SCORE
            </p>
            <p
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                fontSize: 30,
                color: '#ffdd7d',
                fontWeight: 'bolder',
                marginBottom: 0,
              }}>
              {score}
            </p>
          </div>
        </div>
        {/* Class Rank */}
        <div className="col-12 col-sm-6 col-lg-3 d-flex align-items-center border-top-0 border-start-0 border border-1 border-muted">
          <div className="col-3">
            <Image
              preview={false}
              className="img-fluid p-2"
              src={ClassRank}
              alt="Group rank logo"
            />
          </div>
          <div className="col-9 ps-3 d-flex flex-column align-items-start justify-content-center  ">
            <p className="fw-bolder mb-0 text-custom-grey fs-5 w-100 text-truncate">
              GROUP RANK
            </p>
            <p
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                fontSize: 30,
                color: '#ceea89',
                marginBottom: 0,
              }}>
              {selectedStudent?.school?.classesschool?.[0]?.statistic
                ?.averageScore ?? 0}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
