import {Progress, Typography} from 'antd';
import AudioMediaPlayer from './AudioMediaPlayer';
import {useEffect, useState} from 'react';
import {Answer, AnswerService} from '../../lib';
import {getFileUrlByKey} from '../../utils/Storage';
import {GetUrlWithPathOutput} from 'aws-amplify/storage';
import awsconfig from '../../amplifyconfiguration.json';
interface AnswerProps {
  answerId: string;
  currentPlayingId: string | null;
  index: number;
  stopAudioPlayback: boolean;
  isPlaying: boolean;
  onPlay: () => void;
}

const answerService = new AnswerService();

const AnswerComponent: React.FC<AnswerProps> = ({
  answerId,
  currentPlayingId,
  index,
  stopAudioPlayback,
  isPlaying,
  onPlay,
}) => {
  const {Text} = Typography;

  const [answer, setAnswer] = useState<Answer>();
  const [ansuri, setAnsUri] = useState<string>('');

  useEffect(() => {
    async function fetchData() {
      const answerRes = await answerService.getAnswer(answerId);
      console.log('answerRes', answerRes);
      if (!answerRes?.audioUri) {
        // alert('Error in your audio');
        return;
      }

      const uri: GetUrlWithPathOutput = await getFileUrlByKey(
        'public/' + answerRes?.audioUri,
        awsconfig.aws_user_files_s3_bucket,
      );

      setAnsUri(uri.url.toJSON());
      setAnswer(answerRes);
    }
    fetchData();
  }, [answerId]);
  console.log('ASDSDDDSSSAA', ansuri, ' :-<', answer);
  return (
    <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
      <AudioMediaPlayer
        title={`Answer ${index + 1}`}
        stopPlayback={stopAudioPlayback}
        src={ansuri}
        isPlaying={isPlaying}
        onPlay={onPlay}
        isAudio={answer?.audioUri === 'undefined' ? false : true}
        answerId={answerId}
        currentPlayingId={currentPlayingId}
      />
      <Progress
        size={50}
        format={() => <div />}
        type="circle"
        percent={Number(answer?.score?.toFixed(2))}
        strokeWidth={5}
        strokeColor={{'0%': '#F2406A', '100%': '#FFD252'}}
      />
      <Text
        style={{
          fontFamily: 'Arial Rounded MT Bold',
          fontSize: 18,
          marginLeft: 5,
        }}>
        {answer?.score ?? 0}%
      </Text>
    </div>
  );
};

export default AnswerComponent;
